import * as React from 'react';
import * as DxDom from './DxDom';
import { Div, H3, H4, Hr } from './CleanSlate';
import { TypeWrapper } from './StyledElements';
import { MultipleResponses } from './MultipleResponse';
import styled from 'styled-components';
import { AuthorizationLoader } from './Authorization/AuthorizationLoader';
import { DocumentContextConsumer } from './DocumentContext';
import { DataModelContextConsumer } from './DataModelContext';
import { AuthorizationProps } from './Authorization/Authorization';
import { PortalSettings } from './PortalSettings';

const StyledHr = styled(Div)`
  width: calc(100% + 190px);
  height: 67px;
  background: ${(props) => props.theme.colors.C201};
  margin: 0 -200px 0 -80px;
  border-top: 1px solid ${(props) => props.theme.colors.C300};
`;
StyledHr.displayName = 'StyledHr';

const EndpointNodeWrapper = styled(Div)``;
EndpointNodeWrapper.displayName = 'EndpointNodeWrapper';

const Parameters = styled(H4)`
  display: none;
  margin: 0;
  @media screen and (max-width: 500px) {
    display: block;
  }
`;
Parameters.displayName = 'Parameters';

const ExplorerWrapper = styled(Div)`
  ${H3} {
    color: ${(props) => props.theme.colors.C901};
    border-bottom: 1px solid ${(props) => props.theme.colors.C300};
    border-width: 1px;
    font-weight: 500;
    padding-bottom: 15px;
    font-size: 20.25px;
    margin-right: -200px;
    margin-bottom: 0;
  }
  & > ${Div} {
    padding: 30px 0;
  }

  ${Hr} {
    margin: 0 -80px;
  }
`;
ExplorerWrapper.displayName = 'ExplorerWrapper';

/**
 * Render an endpoint reference
 * @param eref DX Endpoint reference element
 * @param nodeRenderer Renderer for DX section nodes
 */
export function renderEndpointReference(
  eref: DxDom.EndpointReference,
  nodeRenderer: DxDom.NodeHandler<JSX.Element>,
  portalSettings: PortalSettings
): JSX.Element {
  const nodes: JSX.Element[] = [];

  const renderNode = (n: DxDom.SectionNode) =>
    DxDom.callNodeHandler(n, nodeRenderer);

  let i = 0;
  const pushNode = (n: DxDom.SectionNode) =>
    nodes.push(
      <React.Fragment key={i++}>
        <EndpointNodeWrapper>{renderNode(n)}</EndpointNodeWrapper>
      </React.Fragment>
    );

  // Generate endpoint description
  if (eref.Description) {
    pushNode({
      Type: 'paragraph',
      Text: eref.Description,
    });
  }

  if (!eref.PlaygroundTitle) {
    // Add method signature
    nodes.push(<Div key={i++}>{renderNode(eref.MethodSignature)}</Div>);
  }

  // Authorization Component
  nodes.push(
    <DocumentContextConsumer>
      {(doc) =>
        doc &&
        doc.Version === '1.0.0' &&
        (eref.AuthModelSchema ? (
          <AuthorizationLoader endpointRef={eref} doc={doc} />
        ) : (
          <DataModelContextConsumer>
            {(dataModelContext) =>
              dataModelContext && (
                <ClearDataModelContext dataModelContext={dataModelContext} />
              )
            }
          </DataModelContextConsumer>
        ))
      }
    </DocumentContextConsumer>
  );

  // Param-Console tabs
  nodes.push(
    <ExplorerWrapper key="usage-example">
      <H3>{eref.PlaygroundTitle || 'API Code Playground'}</H3>
      <Div>{renderNode(eref.UsageExample)}</Div>
      <Hr />
    </ExplorerWrapper>
  );

  // nodes.push(<StyledHr key="page-divider" />);

  // Generate server heading
  if (eref.ServerName) {
    pushNode({
      Type: 'heading',
      Text: 'Server',
      Level: 2,
    });
    pushNode({
      Type: 'paragraph',
      Text: '`' + eref.ServerName + '`',
    });
  }

  // Generate required scopes
  if (eref.Scopes && eref.Scopes.length > 0) {
    pushNode({
      Type: 'heading',
      Text: 'Requires scope',
      Level: 2,
    });
    pushNode({
      Type: 'paragraph',
      Text: eref.Scopes.map((e) => '`' + e + '`').join(', '),
    });
  }

  if (eref.Response && eref.Response.length > 0) {
    // Generate multiple responses
    pushNode({
      Type: 'heading',
      Text: 'Responses',
      Level: 2,
    });

    nodes.push(
      <MultipleResponses
        responses={eref.Response}
        renderNode={renderNode}
        portalSettings={portalSettings}
      />
    );
  } else {
    // Generate return value
    pushNode({
      Type: 'heading',
      Text: 'Response Type',
      Level: 2,
    });
    nodes.push(
      <TypeWrapper key="response-type">
        {renderNode({
          Type: 'paragraph',
          Text: eref.ResponseText
            ? eref.ResponseText
            : eref.ReturnTypeLink
            ? '[`' + eref.ReturnType + '`](' + eref.ReturnTypeLink + ')'
            : '`' + eref.ReturnType + '`',
        })}
      </TypeWrapper>
    );

    // Generate return value
    if (eref.ReturnTypeExample) {
      pushNode({
        Type: 'heading',
        Text: eref.ReturnTypeExample.Language
          ? 'Example Response'
          : 'Example Response (as JSON)',
        Level: 2,
      });

      pushNode(eref.ReturnTypeExample);
    }

    // Generate errors list
    if (eref.Errors && eref.Errors.length > 0) {
      pushNode({
        Type: 'heading',
        Text: 'Errors',
        Level: 2,
      });

      const errorTableRows: DxDom.TableRow[] = [];

      for (const errorKey in eref.Errors) {
        /* istanbul ignore else */
        if (eref.Errors.hasOwnProperty(errorKey)) {
          const error = eref.Errors[errorKey];
          const tr = {
            Type: 'tablerow',
            Data: Array<string>(),
          };
          const errorName = error.Name ? `\`${error.Name}\`` : '';

          tr.Data = [
            error.StatusCode,
            error.Description,
            !error.LinkTo
              ? errorName
              : '[`' + error.Name + '`](' + error.LinkTo + ')',
          ];

          errorTableRows.push(tr);
        }
      }

      const errorTable: DxDom.Table = {
        Type: 'table',
        Header: {
          Data: ['HTTP Status Code', 'Error Description', 'Exception Class'],
        },
        Rows: errorTableRows,
      };

      pushNode(errorTable);
    }
  }

  return <React.Fragment>{nodes}</React.Fragment>;
}
type ClearDataModelContextProps = Pick<AuthorizationProps, 'dataModelContext'>;
export const ClearDataModelContext = (props: ClearDataModelContextProps) => {
  const authDataModel = props.dataModelContext.dataModel.auth;

  if (authDataModel && Object.keys(authDataModel).length) {
    props.dataModelContext.updateAuthDataModel({
      ...props.dataModelContext,
      dataModel: {
        ...props.dataModelContext.dataModel,
        auth: {},
      },
    });
  }

  return <></>;
};
