import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const PlusIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M22 11.5v3c0 0.828-0.672 1.5-1.5 1.5h-6.5v6.5c0 0.828-0.672
     1.5-1.5 1.5h-3c-0.828 0-1.5-0.672-1.5-1.5v-6.5h-6.5c-0.828 
     0-1.5-0.672-1.5-1.5v-3c0-0.828 0.672-1.5 1.5-1.5h6.5v-6.5c0-0.828 
     0.672-1.5 1.5-1.5h3c0.828 0 1.5 0.672 1.5 
     1.5v6.5h6.5c0.828 0 1.5 0.672 1.5 1.5z"
    />
  </SVGIcon>
);
