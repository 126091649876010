import loadable from 'react-loadable';
import {
  AuthorizationLoaderBlock,
  AuthorizationLoaderBlockProps,
} from './AuthorizationLoaderBlock';
import { AuthorizationLoadingWrapper } from './AuthorizationWrapper';

/**
 * Async-loader for Authorization
 */
export const AuthorizationLoader = loadable<
  AuthorizationLoaderBlockProps,
  typeof AuthorizationLoaderBlock
>({
  loader: () =>
    import('./AuthorizationLoaderBlock').then(
      (e) => e.AuthorizationLoaderBlock
    ),
  loading: () => <AuthorizationLoadingWrapper />,
});
