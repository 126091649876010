import ReactMarkdown from 'react-markdown';
import styled, { css } from 'styled-components';
import { ArrowCircleLeftIcon } from '../../Icons/ArrowCircleLeftIcon';
import { BotIconSmall2 } from '../../Icons/BotIconSmall2';
import { ChatMessageComponent } from '../chat-messages/ChatMessages';
import { extractQuestions } from '../../utils/util-methods';
import { DisclaimerHeight, HeaderHeight } from '../../typings';
import { ChatMessage } from '@dx-portal/design-system';

interface WelcomeMessageComponentProps {
  fullHeight: boolean;
  dimensions: { height: number; width: number };
  isMaximised: boolean;
}

const WelcomeMessageComponent = styled.div<WelcomeMessageComponentProps>`
  width: 100%;
  height: ${({ dimensions }) => `
    calc(${dimensions.height}px - 118px - ${HeaderHeight} - ${DisclaimerHeight})
  `};
  padding: 5px 16px;
  display: grid;
  background-color: '#F7F7F8';
  overflow: auto;
`;

interface SuggestedPromptsWrapperProps {
  isMaximised: boolean;
  noDisplay: boolean;
  multiElements: boolean;
}

const SuggestedPromptsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SuggestedPrompts = styled.div<SuggestedPromptsWrapperProps>`
  display: flex;
  align-content: end;
  flex-wrap: wrap;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  ${({ isMaximised }) =>
    isMaximised &&
    css`
      @media screen and (min-width: 1280px) {
        width: 746px;
      }
      @media screen and (max-width: 680px) {
        width: calc(60vw + 18px + 28px);
      }
      width: calc(550px + 18px + 28px);
    `}
`;

export const SuggestedPrompt = styled.div`
  display: flex;
  gap: 6px;
  background-color: ${({ theme }) => theme.staticColors.Snow.C300};
  border: 1px solid ${({ theme }) => theme.staticColors.Goose.C400};
  border-radius: 8px;
  cursor: pointer;
  padding: 6px 18px 6px 8px;

  /* Hover effect */
  transition: background-color 0.3s ease; /* Smooth transition */

  &:hover {
    background-color: #e0e5eb; /* Change background color on hover */
  }
`;

export const SuggestedPromptText = styled.span`
  font-size: 13.33px;
  font-weight: 400;
  line-height: 16.13px;
  text-align: left;
  align-self: flex-start;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
  margin: 10px 0 0 0;
`;

const Subtitle = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  font-variation-settings: 'slnt' 0;
  margin: 5px 0 0 0;
`;

export const IconWrapper = styled.div`
  height: 16px;
  width: 16px;
  display: inline-block;
  padding: 0;
`;

export interface WelcomeMessageProps {
  welcomeMessage: string;
  isMaximised: boolean;
  onPromptClick: (prompt: ChatMessage) => void;
  dimensions: { height: number; width: number };
}

export function WelcomeMessage({
  welcomeMessage,
  isMaximised,
  onPromptClick,
  dimensions,
}: WelcomeMessageProps) {
  const { cleanWelcomeMessage, questions } = extractQuestions(welcomeMessage);
  return (
    <WelcomeMessageComponent
      fullHeight={questions.length >= 7}
      dimensions={dimensions}
      isMaximised={isMaximised}
    >
      <TitleSection>
        <BotIconSmall2 />
        {cleanWelcomeMessage === '' ? (
          <>
            <Title>Welcome to API Copilot</Title>
            <Subtitle>Please ask me anything</Subtitle>
          </>
        ) : (
          <ChatMessageComponent
            isMaximised={isMaximised}
            shouldblink={false}
            style={{
              width: isMaximised ? '56%' : '450px',
              textAlign: 'center',
              margin: '10px 0',
            }}
          >
            <ReactMarkdown>{cleanWelcomeMessage}</ReactMarkdown>
          </ChatMessageComponent>
        )}
      </TitleSection>
      <SuggestedPromptsWrapper>
        <SuggestedPrompts
          multiElements={questions.length > 2}
          isMaximised={isMaximised}
          noDisplay={!questions.length}
        >
          {questions.map((question, index) => (
            <SuggestedPrompt
              key={index}
              onClick={() => {
                onPromptClick({ role: 'user', content: question });
              }}
            >
              <IconWrapper>
                <ArrowCircleLeftIcon />
              </IconWrapper>
              <SuggestedPromptText>{question}</SuggestedPromptText>
            </SuggestedPrompt>
          ))}
        </SuggestedPrompts>
      </SuggestedPromptsWrapper>
    </WelcomeMessageComponent>
  );
}

export default WelcomeMessage;
