import { ChatbotProps } from 'src/typings/aichatbot';
import { ApiCopilot, APICoPilotEvent } from '@apimatic/api-copilot';
import {
  openedAPICoPilotEvent,
  closedAPICoPilotEvent,
  messageSentAPICoPilotEvent,
  gotErrorAPICoPilotEvent,
  clearedChatAPICoPilotEvent,
  copiedCodeAPICoPilotEvent,
  clickedSuggestedPromptAPICoPilotEvent,
  copiedAPICoPilotResponseEvent,
  apiCoPilotResponseFeedbackEvent,
} from '../Analytics';
import { ChatMessages, UserFeedback } from '@dx-portal/design-system';

export const AIChatbot = ({
  apikey,
  language,
  portalSettings,
  layoutContainerRef,
}: ChatbotProps) => {
  const logEvent = (
    event: APICoPilotEvent,
    chatMessages?: ChatMessages,
    apiCopilotResponse?: string,
    feedback?: UserFeedback,
    completeFeedback?: string
  ) => {
    switch (event) {
      case 'Opened':
        openedAPICoPilotEvent(portalSettings, language);
        break;

      case 'Closed':
        closedAPICoPilotEvent(portalSettings, language);
        break;

      case 'MessageSent':
        chatMessages &&
          messageSentAPICoPilotEvent(portalSettings, language, chatMessages);
        break;

      case 'ErrorInResponse':
        chatMessages &&
          gotErrorAPICoPilotEvent(portalSettings, language, chatMessages);
        break;

      case 'ClearedChat':
        clearedChatAPICoPilotEvent(portalSettings, language);
        break;

      case 'CopiedTheCode':
        copiedCodeAPICoPilotEvent(portalSettings, language);
        break;

      case 'CopiedTheResponse':
        apiCopilotResponse &&
          copiedAPICoPilotResponseEvent(
            portalSettings,
            language,
            apiCopilotResponse
          );
        break;

      case 'ClickedOnSuggestedPrompts':
        clickedSuggestedPromptAPICoPilotEvent(portalSettings, language);
        break;

      case 'FeedbackSubmitted':
        feedback &&
          chatMessages &&
          apiCoPilotResponseFeedbackEvent(
            portalSettings,
            language,
            feedback,
            chatMessages,
            completeFeedback
          );
        break;

      default:
        break;
    }
  };

  return (
    <ApiCopilot
      apikey={apikey}
      language={language}
      logEvent={logEvent}
      portalSettings={portalSettings}
      layoutContainerRef={layoutContainerRef}
    />
  );
};
