import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const LinkIcon = (props: SVGIconProps) => (
  <SVGIcon
    width="11.328"
    height="11.345"
    viewBox="0 0 11.328 11.345"
    {...props}
  >
    <g transform="translate(0 0.021)">
      <path
        /* eslint-disable max-len */
        d="M15.47,8.182v4.906H7.235V4.853h4.906V3.618H6.618A.618.618,0,0,0,6,4.235v9.47a.618.618,0,0,0,.618.618h9.47a.618.618,0,0,0,.618-.618V8.182Zm-1.8-5.2V4.214l1.543.021-3.07,3.07h0a.617.617,0,0,0,.872.872h0l3.071-3.07,0,1.558h1.235L17.313,3Z"
        transform="translate(-6 -3)"
      />
    </g>
  </SVGIcon>
);
