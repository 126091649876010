type ThumbsDownIconProp = {
  isActive: boolean;
};

export const ThumbsDownIcon = ({ isActive }: ThumbsDownIconProp) => {
  return isActive ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50002 15.1L8.33335 11.6667H3.47502C3.21627 11.6667 2.96109 11.6064 2.72966 11.4907C2.49823 11.375 2.29693 11.207 2.14168 11C1.98644 10.793 1.88152 10.5527 1.83523 10.2981C1.78895 10.0436 1.80257 9.78172 1.87502 9.53332L3.81668 2.86666C3.91766 2.52046 4.12819 2.21636 4.41668 1.99999C4.70518 1.78362 5.05607 1.66666 5.41668 1.66666H16.6667C17.1087 1.66666 17.5326 1.84225 17.8452 2.15481C18.1578 2.46737 18.3334 2.8913 18.3334 3.33332V9.99999C18.3334 10.442 18.1578 10.8659 17.8452 11.1785C17.5326 11.4911 17.1087 11.6667 16.6667 11.6667H14.3667C14.0566 11.6668 13.7527 11.7535 13.4892 11.9169C13.2257 12.0803 13.013 12.314 12.875 12.5917L10 18.3333C9.60704 18.3285 9.22023 18.2348 8.86851 18.0595C8.51679 17.8841 8.20924 17.6316 7.96885 17.3207C7.72845 17.0097 7.56142 16.6485 7.48024 16.264C7.39905 15.8795 7.40581 15.4815 7.50002 15.1Z"
        fill="#6B7E8F"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 11.6667V1.66666"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 11.6667V1.66666"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50002 15.1L8.33335 11.6667H3.47502C3.21627 11.6667 2.96109 11.6064 2.72966 11.4907C2.49823 11.375 2.29693 11.207 2.14168 11C1.98644 10.793 1.88152 10.5527 1.83523 10.2981C1.78895 10.0436 1.80257 9.78172 1.87502 9.53332L3.81668 2.86666C3.91766 2.52046 4.12819 2.21636 4.41668 1.99999C4.70518 1.78362 5.05607 1.66666 5.41668 1.66666H16.6667C17.1087 1.66666 17.5326 1.84225 17.8452 2.15481C18.1578 2.46737 18.3334 2.8913 18.3334 3.33332V9.99999C18.3334 10.442 18.1578 10.8659 17.8452 11.1785C17.5326 11.4911 17.1087 11.6667 16.6667 11.6667H14.3667C14.0566 11.6668 13.7527 11.7535 13.4892 11.9169C13.2257 12.0803 13.013 12.314 12.875 12.5917L10 18.3333C9.60704 18.3285 9.22024 18.2348 8.86851 18.0595C8.51679 17.8841 8.20924 17.6316 7.96885 17.3207C7.72845 17.0097 7.56142 16.6485 7.48024 16.264C7.39905 15.8795 7.40581 15.4815 7.50002 15.1V15.1Z"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
