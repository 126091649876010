import { PureComponent } from 'react';
import styled from 'styled-components';

import { withPortalContext } from '../PortalContext';
import { DownloadSdkButtonComp } from './DownloadSdkButton';
import { PopupComp } from './GetSdkPopup';
import { PublishedPackage } from '../DxDom';
import { Language, PortalSettings } from '../PortalSettings';
import { A, Div } from '../CleanSlate';
import { GetSdkButtonStyleMixin, PrimaryButtonMixin } from '../StyledElements';
import Tooltip from 'src/Tooltip/Tooltip';
import { disbaledGetSDKViaDoc } from 'src/Analytics';
import { GET_SDK_TOOLTIP_TEXT } from 'src/constants';
import { isSafari } from 'src/Utilities/utility';

interface GetSdkButtonStyleProps {
  disabled?: boolean;
  enableExport: boolean;
  isInlineNavbar: boolean;
}
export const GetSdkButtonStyle = styled(A)<GetSdkButtonStyleProps>`
  ${PrimaryButtonMixin};
  ${GetSdkButtonStyleMixin};
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  opacity: ${(props) => (props.disabled ? '0.5' : '0.85')};
  @media screen and (max-width: 990px) {
    ${(props) =>
      props.isInlineNavbar && !props.enableExport
        ? `
				min-width: 100px;
        margin-right: 0px;
			`
        : ''};
  }
`;
GetSdkButtonStyle.displayName = 'GetSdkButtonStyle';

interface GetSdkProps {
  packageInfo?: PublishedPackage;
  currentTemplate: Language;
  selectedPlatform: string;
  isInlineNavbar: boolean;
  disabled: boolean;
}

type GetSdkCompProps = GetSdkProps & { portalSettings: PortalSettings };

class GetSdkComp extends PureComponent<GetSdkCompProps> {
  renderSdkPopup(sdkDownloadLink: string | undefined) {
    const {
      packageInfo,
      currentTemplate,
      portalSettings,
      selectedPlatform,
      isInlineNavbar,
    } = this.props;
    return (
      <PopupComp
        packageInfo={packageInfo}
        currentTemplate={currentTemplate}
        portalSettings={portalSettings}
        sdkDownloadLink={sdkDownloadLink}
        selectedPlatform={selectedPlatform}
        isInlineNavbar={isInlineNavbar}
      />
    );
  }

  renderViewSource() {
    const {
      packageInfo,
      portalSettings: { enableExport },
      isInlineNavbar,
    } = this.props;
    return (
      packageInfo && (
        <GetSdkButtonStyle
          href={packageInfo.SourceLink}
          target="_blank"
          enableExport={enableExport}
          isInlineNavbar={isInlineNavbar}
        >
          Get SDK
        </GetSdkButtonStyle>
      )
    );
  }

  renderDownloadSDK(sdkDownloadLink: string | undefined) {
    const { currentTemplate, portalSettings, isInlineNavbar } = this.props;

    return sdkDownloadLink ? (
      <GetSdkButtonStyle
        href={sdkDownloadLink}
        target="_blank"
        enableExport={portalSettings.enableExport}
        isInlineNavbar={isInlineNavbar}
      >
        Get SDK
      </GetSdkButtonStyle>
    ) : (
      <DownloadSdkButtonComp
        template={currentTemplate}
        portalSettings={portalSettings}
        name="Get SDK"
        isInlineNavbar={isInlineNavbar}
      />
    );
  }

  logDisableEvent = () => {
    const { currentTemplate, portalSettings } = this.props;

    disbaledGetSDKViaDoc(portalSettings, currentTemplate);
  };

  disableDownloadButton() {
    const {
      disabled,
      currentTemplate,
      isInlineNavbar,
      portalSettings: { enableExport },
    } = this.props;
    const textMessage =
      GET_SDK_TOOLTIP_TEXT[currentTemplate] || GET_SDK_TOOLTIP_TEXT['other'];
    const isLaptopView = window.innerWidth > 990;
    const offsetFirstItem = isLaptopView ? 70 : 0;

    if (isSafari()) {
      return (
        <Div title={textMessage}>
          <GetSdkButtonStyle
            disabled={disabled}
            target="_blank"
            enableExport={enableExport}
            isInlineNavbar={isInlineNavbar}
          >
            Get SDK
          </GetSdkButtonStyle>
        </Div>
      );
    }

    return (
      <Tooltip
        text={textMessage}
        onMouseEnter={this.logDisableEvent}
        parentRefranceId={isLaptopView ? 'left-navbar-parent' : undefined}
        offset={[offsetFirstItem, 10]}
        placement="bottom"
      >
        <GetSdkButtonStyle
          disabled={disabled}
          target="_blank"
          enableExport={enableExport}
          isInlineNavbar={isInlineNavbar}
        >
          Get SDK
        </GetSdkButtonStyle>
      </Tooltip>
    );
  }

  render() {
    const {
      currentTemplate,
      packageInfo,
      portalSettings: { languageSettings },
      disabled,
    } = this.props;
    const currentLanguageSettings = languageSettings[currentTemplate];
    const sdkDownloadLink = currentLanguageSettings!.sdkDownloadLink;
    const disableSdkDownload = currentLanguageSettings!.disableSdkDownload;
    const showDownloadAction =
      !disableSdkDownload &&
      !packageInfo?.InstallCommand &&
      !packageInfo?.Link &&
      !packageInfo?.SourceLink;

    const showViewSourceAction =
      disableSdkDownload &&
      !!(packageInfo && !packageInfo!.Version && packageInfo!.SourceLink);

    const showSdkPopup = !!(
      (packageInfo && (packageInfo.Version || packageInfo.SourceLink)) ||
      (showViewSourceAction && showDownloadAction)
    );

    if (
      disabled &&
      !packageInfo?.SourceLink &&
      !packageInfo?.InstallCommand &&
      !packageInfo?.Link
    )
      return this.disableDownloadButton();
    if (showDownloadAction) return this.renderDownloadSDK(sdkDownloadLink);
    if (showSdkPopup) return this.renderSdkPopup(sdkDownloadLink);
    return null;
  }
}

export const GetSdk = withPortalContext(GetSdkComp);
