/**
 * Implementation for Menu show on the side or in case of embedded mode, just below
 * the navbar. Used for navigating between sections of a document.
 *
 * The vertical mode also shows a "Powered by APIMatic" message.
 *
 * A change in route also updates the selected item in this menu.
 */
import { Component } from 'react';
import styled from 'styled-components';

import { A, Div, Span } from '../CleanSlate';
import { NavItem, PublishedPackage } from '../DxDom';
import { FlexParent } from '../StyledElements';
import { LinkMapper } from '../LinkMapperContext';
import { Language } from '../PortalSettings';
import { RMenuWrapperStyle, RoutedMenu } from '../Menu/RoutedMenu';
import { Navbar } from 'src/Navbar/Navbar';
import { FireReadyArgs, ReadyArgs } from 'src/Context/EventsContext';
import { Steps } from 'src/workflow-steps';
import {
  getSteps,
  WorkflowContextType,
  WorkflowState,
} from 'src/Context/WorkflowContext';
import { BackToNavBarMenu } from 'src/BackToWorkflowMenu';
import { HandleOutsideClick } from 'src/HandleOutsideClick';
import { SelectSteps } from './SelectSteps';
import SidebarSkeleton from './SidebarSkeleton';

export interface SidebarProps {
  lang: Language;
  linkMapper?: LinkMapper;
  packageInfo?: PublishedPackage;
  nav: ReadonlyArray<NavItem>;
  whiteLabel?: boolean;
  onSelect?: (key: string) => void;
  horizontal?: boolean;
  disabled?: boolean;
  readyArgs: FireReadyArgs;
  readyArgsInstance: ReadyArgs;
  workflowSteps?: WorkflowState[string];
  workflowName?: string;
  workflowPageTitle?: string;
  onStepClick?: WorkflowContextType['onStepClick'];
}

type SidebarState = {
  openKeys: string[];
  docsTop: number;
  showWorkflowSteps: boolean;
  hasDataModelRefValue: boolean;
};

/**
 * Sidebar message wrapper
 */
const SidebarFooter = styled(Div)`
  height: 40px;
  text-align: center;
  font-size: 11.85px;
  line-height: 40px;
  color: #a5b3bb;
  ${A} {
    strong {
      color: #a5b3bb;
    }
  }
  @media screen and (max-width: 990px) {
    display: none;
  }
`;
SidebarFooter.displayName = 'SidebarFooter';

const SidebarStyles = styled(FlexParent)`
  width: 100%;
  height: 100%;
  max-width: 272px;
  float: right;

  @media screen and (max-width: 1200px) {
    max-width: 242px;
  }

  @media screen and (max-width: 990px) {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    float: none;
    max-width: none;
  }
`;
const WorkflowWrapper = styled(RMenuWrapperStyle)`
  @media screen and (min-width: 991px) {
    margin-top: 0px;
    height: calc(100% - 60px);
  }
`;

interface StepsContainerProps {
  docsHeight: string;
  docsTop: string;
  showWorkflowSteps: boolean;
}

const StepsContainer = styled(Div)<StepsContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 22px;
  height: 100%;

  @media screen and (max-width: 990px) {
    visibility: ${(props) => (props.showWorkflowSteps ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.showWorkflowSteps ? '1' : '0')};
    position: absolute;
    top: 69px;
    left: 0px;
    z-index: 100;
    width: 70vw;
    max-width: 300px;
    height: ${(props) => props.docsHeight};
    background: ${(props) => props.theme.colors.C201};
    box-shadow: ${(props) => props.theme.colors.C400} 1px 5px 6px;
    border: ${(props) => props.theme.colors.C300};
  }

  @media screen and (max-width: 500px) {
    top: 53px;
  }
`;

const EndPointGuide = styled(Span)`
  font-size: 15px;
  color: ${(props) => props.theme.staticColors.Goose.C200};
  font-weight: 500;
`;

/**
 * Menu component for navigating document sections
 *
 * Horizontal mode renders a fixed-height drop-down menu instead of a vertical one.
 */
export class Sidebar extends Component<SidebarProps, SidebarState> {
  state: SidebarState = {
    openKeys: [],
    docsTop: 0,
    showWorkflowSteps: false,
    hasDataModelRefValue: false,
  };

  onKeyChange = (keys: string[]) => {
    this.setState({
      openKeys: keys,
    });
  };

  onNavigate = (key: string) => {
    // openKeys remain an empty array in case of horizontal menu
    if (!this.props.horizontal && this.state.openKeys.indexOf(key) === -1) {
      this.setState((s) => ({ openKeys: s.openKeys.concat(key) }));
    }
  };

  resizeCallback = () => {
    const appLayoutTop = (
      document.getElementById('app-layout-docs-container') as HTMLDivElement
    ).offsetTop;
    this.setState({
      docsTop: appLayoutTop,
    });
  };

  componentDidUpdate(
    prevProps: Readonly<SidebarProps>,
    prevState: Readonly<SidebarState>
  ): void {
    const { readyArgs, readyArgsInstance } = this.props;
    const currentDataModelContextRef =
      readyArgs.dataModelRef.current.dataModelContext;

    // Check if dataModelSchema is available and the logic hasn't been executed yet
    if (
      currentDataModelContextRef?.dataModelSchema &&
      readyArgsInstance &&
      !this.state.hasDataModelRefValue
    ) {
      // Update the state to prevent further updates
      this.setState((prevState) => ({
        ...prevState, // Preserve other properties
        hasDataModelRefValue: true,
      }));

      // Perform the logic with the updated readyArgs
      readyArgsInstance.set(readyArgs);
    }
  }

  componentDidMount() {
    const { readyArgs, readyArgsInstance } = this.props;

    if (
      readyArgs.dataModelRef.current?.dataModelContext?.dataModelSchema &&
      readyArgsInstance
    ) {
      this.setState((prevState) => ({
        ...prevState, // Preserve other properties
        hasDataModelRefValue: true,
      }));
      readyArgsInstance.set(readyArgs);
    }

    const appLayoutTop = (
      document.getElementById('app-layout-docs-container') as HTMLDivElement
    ).offsetTop;
    this.setState({
      docsTop: appLayoutTop,
    });
    window.addEventListener('resize', this.resizeCallback);
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resizeCallback);
  };

  onOutsideClick = () => {
    this.setState((prevState) => ({ ...prevState, showWorkflowSteps: false }));
  };

  onSelectStepClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      showWorkflowSteps: !prevState.showWorkflowSteps,
    }));
  };

  render() {
    const {
      disabled,
      lang,
      linkMapper,
      packageInfo,
      horizontal,
      whiteLabel,
      workflowSteps,
      workflowName,
      workflowPageTitle,
      onStepClick: setStepData,
    } = this.props;

    const { selectedStepName, selectedStepValue } = getSteps(workflowSteps);

    return (
      <SidebarStyles
        type={horizontal ? 'row' : 'column'}
        id="left-navbar-parent"
      >
        {disabled ? (
          <SidebarSkeleton />
        ) : (
          <>
            <Navbar
              isWorkflowPage={!!workflowSteps}
              disabled={disabled}
              currentTemplate={lang}
              linkMapper={linkMapper}
              packageInfo={packageInfo}
              onNavigate={this.onNavigate}
            />

            {workflowSteps ? (
              <WorkflowWrapper>
                <HandleOutsideClick onOutsideClick={this.onOutsideClick}>
                  <SelectSteps
                    selectedStepName={selectedStepName}
                    selectedStepDescription={selectedStepValue?.name}
                    showWorkflowSteps={this.state.showWorkflowSteps}
                    onSelectStepClick={this.onSelectStepClick}
                  />

                  <StepsContainer
                    docsHeight={`calc(100% - ${this.state.docsTop + 5}px)`}
                    docsTop={`${this.state.docsTop + 4}px`}
                    showWorkflowSteps={this.state.showWorkflowSteps}
                  >
                    <BackToNavBarMenu
                      lang={lang}
                      workflowName={workflowName}
                      workflowSteps={workflowSteps}
                      closeWorkflow={this.onOutsideClick}
                    />
                    <EndPointGuide>{workflowPageTitle}</EndPointGuide>
                    <Steps
                      steps={workflowSteps}
                      onStepClick={setStepData}
                      workflowName={workflowName}
                      closeWorkflow={this.onOutsideClick}
                    />
                  </StepsContainer>
                </HandleOutsideClick>
              </WorkflowWrapper>
            ) : (
              <RoutedMenu
                {...this.props}
                onOpenKeysChange={this.onKeyChange}
                openKeys={this.state.openKeys}
              />
            )}
          </>
        )}

        {!horizontal && !whiteLabel && (
          <SidebarFooter>
            API Docs by{' '}
            <A
              href="https://www.apimatic.io/"
              target="__blank"
              style={{ fontSize: '12px' }}
            >
              <strong>APIMATIC</strong>
            </A>
          </SidebarFooter>
        )}
      </SidebarStyles>
    );
  }
}
