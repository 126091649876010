import * as React from 'react';
import { P, Em } from './CleanSlate';
import { CodeBox } from './AppLayout';
import { Overlay, CodeBlock } from './StyledElements';
import { Spinner } from './Spinner';

import loadable from 'react-loadable';
import {
  RCompilableCodeBlock,
  RCompilableCodeBlockProps,
} from './RCompilableCodeBlock';
/**
 * Async-loader for RCompilableCodeBlock
 */
export const RCompilableCodeBlockLoader = loadable<
  RCompilableCodeBlockProps,
  typeof RCompilableCodeBlock
>({
  loader: () =>
    import('./RCompilableCodeBlock').then((e) => e.RCompilableCodeBlock),
  loading: () => (
    <React.Fragment>
      <P>
        <Em>Loading console...</Em>
      </P>
      <CodeBox>
        <CodeBlock>
          <Overlay direction="column" opaque={true}>
            <Spinner color="rgba(255, 255, 255, 0.9)" />
          </Overlay>
        </CodeBlock>
      </CodeBox>
    </React.Fragment>
  ),
});
