import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const CopyIcon2 = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '18'}
    height={props.height || '18'}
    fill={props.fill || 'none'}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      d="M15 4H6C4.89543 4 4 4.89543 4 6V15C4 16.1046 4.89543 17 6 17H15C16.1046 17 17 16.1046 17 15V6C17 4.89543 16.1046 4 15 4Z"
      stroke="#6B7E8F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 12V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H12"
      stroke="#6B7E8F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);
