import { Placement } from '@popperjs/core';
import { FC, PropsWithChildren } from 'react';

import { Popover } from 'src/base-components';
import { TOOLTIP_FALLBACK_PLACEMENT, TOOLTIP_PLACEMENT } from 'src/constants';
import { TooltipWrapper } from '.';

type OffsetItem = number | null | undefined;

type OffsetProp = [OffsetItem, OffsetItem];

interface TooltipProps {
  placement?: Placement;
  fallbackPlacements?: Placement[];
  text: string;
  offset?: OffsetProp;
  parentRefranceId?: string;

  onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const defaultOffset: OffsetProp = [0, 10];

const Tooltip: FC<PropsWithChildren<TooltipProps>> = (props) => {
  const {
    children,
    text,
    placement = TOOLTIP_PLACEMENT,
    fallbackPlacements = TOOLTIP_FALLBACK_PLACEMENT,
    offset = defaultOffset,
    parentRefranceId,
    onMouseEnter,
  } = props;
  return (
    <Popover
      placement={placement}
      fallbackPlacements={fallbackPlacements}
      offset={offset}
      onMouseEnter={onMouseEnter}
    >
      {(props) => (
        <TooltipWrapper
          {...props}
          text={text}
          parentRefranceId={parentRefranceId}
        >
          {children}
        </TooltipWrapper>
      )}
    </Popover>
  );
};
export default Tooltip;
