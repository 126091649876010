import styled from 'styled-components';
import { LinkMapperContext } from '../LinkMapperContext';
import { Section } from 'src/DxDom';
import { Div } from '../CleanSlate';
import { marked } from 'marked';
import { Content } from './TableOfContentBodyContent';
import { ListObjects } from './TableOfContentComponent';

export type TableProps = {
  Node?: Section;
  level: number;
  activeList: ListObjects[];
  sideContainerWidth: number;
};

export interface SpanProps {
  link?: string;
  isActive: boolean;
  level: number;
  sideContainerWidth: number;
}

const ElementsWrapper = styled(Div)<SpanProps>`
  padding: 4px 0px;
  width: ${({ sideContainerWidth }) => `${sideContainerWidth - 46}px`};

  &:hover {
    cursor: ${(props) => (props.link ? 'pointer' : '')};
  }
  @media screen and (max-width: 990px) {
    border-left: none;

    &:hover {
      border-left: none;
    }
  }
`;

export const parseTextFromMarkDown = (mdString: string) => {
  const htmlString = marked(mdString);
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const walker = document.createTreeWalker(doc, NodeFilter.SHOW_TEXT);

  const textList = [];
  const dataObject = {
    heading: '',
    href: '',
  };
  let elementHref;
  let currentNode: Node | null = walker.currentNode;

  while (currentNode) {
    if (currentNode.textContent !== '\n' && currentNode.textContent !== '#') {
      textList.push(currentNode.textContent);
    }
    if (currentNode.textContent === '#') {
      const element = currentNode?.parentElement as HTMLAnchorElement;
      elementHref = element?.href;
    }
    currentNode = walker.nextNode();
  }

  const filteredText = textList.filter((listItem) => {
    return listItem !== null && listItem !== ' ';
  });

  const trimedFilteredText = filteredText.map((listItem) => listItem?.trim());

  const singleTextString = trimedFilteredText.join(' ');

  dataObject.heading = singleTextString;
  elementHref
    ? (dataObject.href = elementHref.replace(/(.*)#(.*)/gim, '$2'))
    : (dataObject.href = '');

  /*
    if the node contains any anchor tag it gets its href and inserts into elementHref variable. 
    the href is obtained in form of a string. 
    That string is divided by a "#" we only require the part of string that comes after "#". 
    So replace method is used that groups the string into two parts. 
    The first part contains string content before "#" and the second part contains content after "#". 
    The second group is selected and replaces the content of elementHref variable.
    */

  return dataObject;
};

export default function ContentTable({
  Node,
  level,
  activeList,
  sideContainerWidth,
}: TableProps) {
  const { SuggestedLink, Nodes, Steps, Type } = Node || {};

  const isNodeNotEmpty = Nodes?.length !== 0;
  const isNotFirstLevel = level !== 0;
  const condition =
    isNodeNotEmpty &&
    isNotFirstLevel &&
    (Type === 'section' || Type === 'step') &&
    SuggestedLink;

  const nodes = Nodes || Steps;

  return (
    <>
      {condition && (
        <LinkMapperContext.Consumer>
          {(linkMapper) => (
            <ElementsWrapper
              link={SuggestedLink}
              level={level}
              isActive={activeList[0]?.href === linkMapper(SuggestedLink)}
              sideContainerWidth={sideContainerWidth}
            >
              <Content
                linkMapper={linkMapper}
                Node={Node}
                level={level}
                activeList={activeList}
                sideContainerWidth={sideContainerWidth}
              />
            </ElementsWrapper>
          )}
        </LinkMapperContext.Consumer>
      )}
      {nodes?.map((child, index) => {
        return (
          <ContentTable
            key={`TOC_${index}`}
            Node={child as Section}
            level={level + 1}
            activeList={activeList}
            sideContainerWidth={sideContainerWidth}
          />
        );
      })}
    </>
  );
}
