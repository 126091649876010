import { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled, { keyframes, css } from 'styled-components';
import { BotIconSmall } from '../../Icons/BotIconSmall';
import { UserIcon } from '../../Icons/UserIcon';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyToClipboardIcon } from '../../../../dx-design-system/src/icons/CopyToClipboard';
import { CrossIconRed } from '../../Icons/CrossIconRed';
import { WelcomeMessage } from '../welcome-message/WelcomeMessage';
import { AppContext } from '../ApiCopilot';
import { DisclaimerHeight, HeaderHeight } from '../../typings';
import {
  ChatMessage,
  ChatMessages,
  FeedbackForm,
  Tooltip,
  Theme,
  Feedback,
} from '@dx-portal/design-system';
import { Loader } from '../../Icons/Loader';

interface MessagesWrapperProps {
  isMaximised: boolean;
  dimensions: { height: number; width: number };
}

interface MessageParentProps {
  isMaximised: boolean;
  isUser: boolean;
}

interface IconWrapperProps {
  isUser: boolean;
}

interface ChatMessageProps {
  isMaximised: boolean;
  shouldblink: boolean;
}

interface InternalErrorComponentProps {
  isInternalError?: true;
}

const MessagesWrapper = styled.div<MessagesWrapperProps>`
  height: ${({ dimensions }) =>
    `calc(${dimensions.height}px - 118px - ${HeaderHeight} - ${DisclaimerHeight})`};
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  ${({ isMaximised }) =>
    isMaximised
      ? css`
          align-items: center;
        `
      : css`
          padding: 0 16px;
          align-items: start;
        `}
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  margin: 0 18px 0 0;
  position: relative;
`;

type LoaderWrapperProps = {
  hidden?: boolean;
};

const LoaderWrapper = styled.div<LoaderWrapperProps>`
  position: absolute;
  left: 44px;
  top: 2px;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  gap: 8px;
  align-items: center;
  color: #3e445d;
`;

const MessageParent = styled.div<MessageParentProps>`
  height: fit-content;
  padding: 8px 0px;
  display: flex;
  align-items: start;
  background-color: '#fff';
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow-wrap: break-word;

  ${({ isMaximised }) =>
    isMaximised
      ? css`
          justify-content: center;
        `
      : css`
          justify-content: flex-start;
        `}
`;

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `;
}

export const ChatMessageComponent = styled.div<ChatMessageProps>`
  height: fit-content;
  animation: ${(props) => (props.shouldblink ? blinkingEffect : 'none')} 1s
    linear infinite;

  > p:first-child {
    margin: 0px;
  }

  ${({ isMaximised }) =>
    isMaximised
      ? css`
          @media screen and (min-width: 1280px) {
            width: 700px;
          }
          @media screen and (max-width: 680px) {
            width: 60vw;
          }
          width: 550px;
        `
      : css`
          width: 450px;
        `}
`;

const CopyToClipboardWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const SvgParentDiv = styled.div`
  cursor: pointer;

  &:hover {
    > svg {
      stroke: #fff;
    }
  }
`;

const InternalErrorComponent = styled.div<InternalErrorComponentProps>`
  display: ${(props) => (props.isInternalError ? 'flex' : 'none')};
  align-items: center;
  gap: 5px;
  border-radius: var(--spacings-2, 8px);
  border: solid ${({ theme }) => theme.staticColors.Red.C000};
  border-width: 1px 1px 1px 8px;
  background: #fff0f1;
  position: absolute;
  left: 50%;
  bottom: 25%;
  transform: translateX(-50%);
  padding: 1px 13px;
  opacity: 0.8;
  width: 340px;

  // Typography
  color: ${({ theme }) => theme.staticColors.Red.C000};

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export type ChatMessagesProps = {
  messages: ChatMessages;
  isInputDisabled: boolean;
  isMaximised: boolean;
  isInternalError?: true;
  welcomeMessage: string;
  onPromptClick: (prompt: ChatMessage) => void;
  dimensions: { height: number; width: number };
};

export const ChatMessagesComponent = (props: ChatMessagesProps) => {
  const {
    messages,
    isInputDisabled,
    isMaximised,
    isInternalError,
    onPromptClick,
    welcomeMessage,
    dimensions,
  } = props;
  const { logEvent } = useContext(AppContext);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [preventScroll, setPreventScroll] = useState(false);

  let messageArray = [...messages];

  if (
    messageArray.length &&
    messageArray[messageArray.length - 1].role === 'user'
  ) {
    messageArray = [...messageArray];
  }

  useEffect(() => {
    const wrapperObj = document.getElementById('messages-wrapper');
    if (wrapperObj) {
      if (!preventScroll) {
        wrapperObj.scrollTop = wrapperObj.scrollHeight;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!isInputDisabled) return;
    const currentScrollPos = e.currentTarget.scrollTop;

    if (currentScrollPos < prevScrollPos) {
      setPreventScroll(true);
    } else setPreventScroll(false);

    setPrevScrollPos(currentScrollPos);
  };

  const handleFeedbackSubmission = (feedback: Feedback) => {
    logEvent &&
      feedback &&
      logEvent(
        'FeedbackSubmitted',
        messages,
        undefined,
        feedback.feedback,
        feedback.completeFeedback
      );
  };

  const onResponseCopy = () => {
    logEvent &&
      logEvent(
        'CopiedTheResponse',
        undefined,
        messages[messages.length - 1].content
      );
  };

  return messageArray.length ? (
    <MessagesWrapper
      id="messages-wrapper"
      data-testid="api-copilot-message-component"
      onScroll={handleScroll}
      style={{
        justifyContent: messageArray.length || !isMaximised ? 'start' : 'end',
      }}
      isMaximised={isMaximised}
      dimensions={dimensions}
    >
      {messageArray.map((message, index) => {
        const isUser = message.role === 'user';
        return (
          <MessageParent key={index} isUser={isUser} isMaximised={isMaximised}>
            <IconWrapper isUser={isUser}>
              {isUser ? <UserIcon /> : <BotIconSmall />}
              <LoaderWrapper hidden={message.content !== 'Thinking...'}>
                <Loader />
                Thinking...
              </LoaderWrapper>
            </IconWrapper>
            <ChatMessageComponent
              data-testid={'api-copilot-chat-message-component'}
              isMaximised={isMaximised}
              shouldblink={message.content === 'Thinking...'}
            >
              <ReactMarkdown
                children={
                  message.content === 'Thinking...' ? '' : message.content
                }
                components={{
                  code({ node, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');

                    return match ? (
                      <div style={{ position: 'relative', marginTop: '-8px' }}>
                        <SyntaxHighlighter
                          style={dracula}
                          PreTag="div"
                          language={match[1]}
                          children={String(children).replace(/\n$/, '')}
                          customStyle={{ paddingTop: '1.7em' }}
                          {...props}
                        />
                        <CopyToClipboard
                          text={String(children)}
                          onCopy={() => {
                            logEvent && logEvent('CopiedTheCode');
                          }}
                        >
                          <CopyToClipboardWrapper id="copy-to-clipboard-tooltip">
                            <Tooltip
                              text="Copy"
                              parentRefranceId="copy-to-clipboard-tooltip"
                              offset={[0, 20]}
                              children={
                                <SvgParentDiv>
                                  <CopyToClipboardIcon />
                                </SvgParentDiv>
                              }
                            />
                          </CopyToClipboardWrapper>
                        </CopyToClipboard>
                      </div>
                    ) : (
                      <Theme.InlineCode className={className ?? ''} {...props}>
                        {children}
                      </Theme.InlineCode>
                    );
                  },
                }}
              />
              <InternalErrorComponent
                data-testid={'api-copilot-internal-error-component'}
                isInternalError={isInternalError}
              >
                <CrossIconRed />
                <p>Oops! Something Unexpected happened, Please try again.</p>
              </InternalErrorComponent>
              {shouldShowFeedbackForm(index, message, messageArray) &&
                !isInputDisabled && (
                  <FeedbackForm
                    platform="apiCopilot"
                    handleFeedbackSubmission={handleFeedbackSubmission}
                    messages={messageArray}
                    onResponseCopy={onResponseCopy}
                  />
                )}
            </ChatMessageComponent>
          </MessageParent>
        );
      })}
    </MessagesWrapper>
  ) : (
    <WelcomeMessage
      welcomeMessage={welcomeMessage}
      isMaximised={isMaximised}
      onPromptClick={onPromptClick}
      dimensions={dimensions}
    />
  );
};

function shouldShowFeedbackForm(
  index: number,
  message: ChatMessage,
  messageArray: ChatMessage[]
) {
  return (
    messageArray.length - 1 === index &&
    index !== 0 &&
    message.content !== 'Thinking...' &&
    message.role !== 'user' &&
    message.content !== ''
  );
}
