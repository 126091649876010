import { PortalSettings } from './PortalSettings';
import { CompilableCodeBlock } from './DxDom';
import { ClipboardSectionType } from './uiComponents/CopyToClipboard';
import { ChatMessages, UserFeedback } from '@dx-portal/design-system';

const docsUrl = window.location.origin + window.location.pathname;

/**
 * Log an analytics event via Segment.
 *
 * Portal metadata like apiKey and document title are automatically added
 * by this method to the event data for all events.
 */
export function trackEvent(
  portalSettings: PortalSettings,
  eventName: string,
  eventData: { [key: string]: unknown }
) {
  // Do not send events if analytics is disabled in the Portal Settings
  if (!portalSettings.enableAnalytics) return;

  // I've purposefully removed the typing for windows.analytics from the
  // global typings so that no one accidentally uses it to log data to the
  // Segment. Instead, everyone must use this trackEvent method properly
  // respects the enableAnalytics flag in the portal settings. Since
  // the typing was removed, we need to cast window as "any" in the line below.

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).analytics.track(eventName, {
    // Send important Portal Settings
    ApiKey: portalSettings.apiKey,
    PortalStyle: portalSettings.portalStyle,
    UseProxyForConsoleCalls: portalSettings.useProxyForConsoleCalls,

    // Send webpage context
    DocsTitle: document.title,
    DocsUrl: docsUrl,

    // Send event-specific data
    ...eventData,
  });
}

export function openedAPICoPilotEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(portalSettings, 'API Copilot - Opened', {
    Template: template,
    DocsURL: window.location.href,
  });
}

export function closedAPICoPilotEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(portalSettings, 'API Copilot - Closed', {
    Template: template,
    DocsURL: window.location.href,
  });
}

export function messageSentAPICoPilotEvent(
  portalSettings: PortalSettings,
  template: string,
  chatMessages: ChatMessages
) {
  trackEvent(portalSettings, 'API Copilot - Message Sent', {
    Template: template,
    DocsURL: window.location.href,
    ChatMessages: chatMessages,
  });
}

export function gotErrorAPICoPilotEvent(
  portalSettings: PortalSettings,
  template: string,
  chatMessages: ChatMessages
) {
  trackEvent(portalSettings, 'API Copilot - Error in Response', {
    Template: template,
    DocsURL: window.location.href,
    ChatMessages: chatMessages,
  });
}

export function clearedChatAPICoPilotEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(portalSettings, 'API Copilot - Cleared Chat', {
    Template: template,
    DocsURL: window.location.href,
  });
}

export function copiedCodeAPICoPilotEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(portalSettings, 'API Copilot - Copied the Code', {
    Template: template,
    DocsURL: window.location.href,
  });
}

export function clickedSuggestedPromptAPICoPilotEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(
    portalSettings,
    'API Copilot - Clicked on Suggested Prompts button',
    {
      Template: template,
      DocsURL: window.location.href,
    }
  );
}

export function copiedAPICoPilotResponseEvent(
  portalSettings: PortalSettings,
  template: string,
  apiCopilotResponse: string
) {
  trackEvent(portalSettings, 'API Copilot - Copied response', {
    Template: template,
    DocsURL: window.location.href,
    APICopilotResponse: apiCopilotResponse,
  });
}

export function apiCoPilotResponseFeedbackEvent(
  portalSettings: PortalSettings,
  template: string,
  feedback: UserFeedback,
  chatMessages: ChatMessages,
  completeFeedback?: string
) {
  trackEvent(portalSettings, 'API Copilot - Feedback Provided', {
    Template: template,
    DocsURL: window.location.href,
    Feedback: feedback,
    CompleteFeedback: completeFeedback,
    ChatMessages: chatMessages,
  });
}

export function viewSDKInstallationInstructionsEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(
    portalSettings,
    'Viewed SDK Package Installation Instructions via Docs',
    {
      Template: template,
      DocsURL: window.location.href,
    }
  );
}

export function copiedSDKInstallationCommandEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(
    portalSettings,
    'Copied SDK Package Installation Command via Docs',
    {
      Template: template,
      DocsURL: window.location.href,
    }
  );
}

export function visitedSDKRepoLinkViaDocsEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(portalSettings, 'Visited SDK Repo Link via Docs', {
    Template: template,
    DocsURL: window.location.href,
  });
}

export function visitedSDKSourceCodeLinkViaDocsEvent(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(portalSettings, 'Visited SDK Source Code Link via Docs', {
    Template: template,
    DocsURL: window.location.href,
  });
}

export function docsLoadedEvent(
  portalSettings: PortalSettings,
  template: string,
  statusCode: number,
  statusText: string
) {
  trackEvent(portalSettings, 'Docs Loading Success', {
    Template: template,
    StatusCode: statusCode,
    StatusText: statusText,
  });
}

export function docsFailureEvent(
  portalSettings: PortalSettings,
  template: string,
  statusCode: number,
  statusText: string,
  apiRoute: string
) {
  trackEvent(portalSettings, 'Docs Loading Failure', {
    Template: template,
    StatusCode: statusCode,
    StatusText: statusText,
    ApiRoute: apiRoute,
  });
}

export function menuClickedEvent(
  portalSettings: PortalSettings,
  template: string,
  menuItemTitle: string,
  navigationKey: string
) {
  trackEvent(portalSettings, 'Docs Menu Clicked', {
    Template: template,
    MenuItemTitle: menuItemTitle,
    NavigationKey: navigationKey,
  });
}

export function docsConsoleCallEvent(
  portalSettings: PortalSettings,
  { Templates, EndpointName, EndpointGroupName }: CompilableCodeBlock,
  statusCode?: number,
  reasonPhrase?: string
) {
  const currentTemplate = Object.keys(Templates)[0];

  trackEvent(portalSettings, 'Docs Console Call', {
    EndpointName: EndpointName,
    EndpointGroupName: EndpointGroupName,
    StatusCode: statusCode,
    StatusText: reasonPhrase,
    CurrentTemplate: currentTemplate,
  });
}

export function sdkDownloadSuccessEvent(
  portalSettings: PortalSettings,
  template: string,
  statusCode?: number,
  statusText?: string,
  apiRoute?: string
) {
  trackEvent(portalSettings, 'Docs SDK Download Success', {
    Template: template,
    StatusCode: statusCode,
    StatusText: statusText,
    ApiRoute: apiRoute,
    DocsURL: window.location.href,
  });
}

export function sdkDownloadFailureEvent(
  portalSettings: PortalSettings,
  template: string,
  statusCode?: number,
  statusText?: string,
  apiRoute?: string
) {
  trackEvent(portalSettings, 'Docs SDK Download Failure', {
    Template: template,
    StatusCode: statusCode,
    StatusText: statusText,
    ApiRoute: apiRoute,
  });
}

export function apiSpecDownloadEvent(
  portalSettings: PortalSettings,
  format: string
) {
  trackEvent(portalSettings, 'Docs API Spec Download', {
    Format: format,
  });
}

export function codeCopiedEvent(
  portalSettings: PortalSettings,
  template: string,
  sectionType: ClipboardSectionType,
  endpointName: string,
  endpointGroupName: string
) {
  trackEvent(portalSettings, 'Docs Code Copied', {
    Template: template,
    EndpointName: endpointName,
    EndpointGroupName: endpointGroupName,
    SectionType: sectionType,
  });
}

export function legacyProxyFailOverEvent(
  portalSettings: PortalSettings,
  template: string,
  endpointName: string,
  endpointGroupName: string
) {
  trackEvent(portalSettings, 'Legacy Proxy Failover', {
    Template: template,
    EndpointName: endpointName,
    EndpointGroupName: endpointGroupName,
  });
}

export function docsConsoleCallOopsError(
  portalSettings: PortalSettings,
  { Templates, EndpointName, EndpointGroupName }: CompilableCodeBlock,
  reasonPhrase?: string
) {
  const currentTemplate = Object.keys(Templates)[0];

  trackEvent(portalSettings, 'Docs Console Call - Oops Error', {
    EndpointName: EndpointName,
    EndpointGroupName: EndpointGroupName,
    StatusText: reasonPhrase,
    CurrentTemplate: currentTemplate,
  });
}

export function docsGetTokenOopsError(
  portalSettings: PortalSettings,
  { Templates, EndpointName, EndpointGroupName }: CompilableCodeBlock,
  reasonPhrase?: string
) {
  const currentTemplate = Object.keys(Templates)[0];

  trackEvent(portalSettings, 'Docs Console Call - Get Token Oops Error', {
    EndpointName: EndpointName,
    EndpointGroupName: EndpointGroupName,
    StatusText: reasonPhrase,
    CurrentTemplate: currentTemplate,
  });
}

export function rjsfFallbackError(
  { Templates, EndpointName, EndpointGroupName }: CompilableCodeBlock,
  portalSettings: PortalSettings,
  reasonPhrase?: string
) {
  const currentTemplate = Object.keys(Templates)[0];

  trackEvent(portalSettings, 'RJSF Fallback Error', {
    EndpointName: EndpointName,
    EndpointGroupName: EndpointGroupName,
    StatusText: reasonPhrase,
    CurrentTemplate: currentTemplate,
    CrashingPoint: 'RJSF',
  });
}

export function disbaledGetSDKViaDoc(
  portalSettings: PortalSettings,
  template: string
) {
  trackEvent(portalSettings, 'Disabled Get SDK via Docs', {
    CurrentTemplate: template,
  });
}

export function onSearchOptionOpen(portalSettings: PortalSettings) {
  trackEvent(portalSettings, 'Portal Search Opened', {});
}

export function logEventOnClickingSearchResult(
  portalSettings: PortalSettings,
  searchKeyword: string,
  searchResultName: string,
  searchResultURL: string
) {
  trackEvent(portalSettings, ' Search Result Clicked', {
    SearchKeyword: searchKeyword,
    SearchResultName: searchResultName,
    SearchResultURL: searchResultURL,
  });
}

export function guidedWalkThroughEvents(
  eventTitle: string,
  portalSettings: PortalSettings,
  walkthroughTitle: string,
  currentStepName?: string,
  stepType?: string,
  statusCode?: number,
  reasonPhrase?: string
) {
  trackEvent(portalSettings, eventTitle, {
    GuidedWalkthroughTitle: walkthroughTitle,
    CurrentStepName: currentStepName,
    StepType: stepType,
    CrashingPoint: 'Guided Walkthrough',
    StatusText: reasonPhrase,
    StatusCode: statusCode,
    CurrentUrl: window.location.href,
  });
}

export function ConfigureWindowEvent(
  portalSettings: PortalSettings,
  status: 'opened' | 'closed'
) {
  trackEvent(portalSettings, 'Configure Button Clicked', {
    ConfigureWindowStatus: status,
  });
}

export function getTokenEvent(portalSettings: PortalSettings) {
  trackEvent(portalSettings, 'Get Token Button Clicked', {});
}

export function getTokenSuccessEvent(portalSettings: PortalSettings) {
  trackEvent(
    portalSettings,
    'Docs Console Call - Token Authorization Successful',
    {}
  );
}

export function requestExamplesDropdownClickEvent(
  portalSettings: PortalSettings,
  endpointName: string
) {
  trackEvent(portalSettings, 'Portal Request Examples Opened', {
    endpointName,
  });
}

export function requestExamplesDropdownMenuOptionSelectionEvent(
  portalSettings: PortalSettings,
  endpointName: string,
  exampleName?: string
) {
  trackEvent(portalSettings, 'Portal Request Example Clicked', {
    endpointName,
    exampleName,
  });
}

export function languageSwitchEvent(
  portalSettings: PortalSettings,
  previouslySelectedLanguage: string,
  newlySelectedLanguage?: string
) {
  trackEvent(portalSettings, 'Portal Languge Switched', {
    previousLanguage: previouslySelectedLanguage,
    newLanguage: newlySelectedLanguage,
  });
}

export function showCompleteFileEvent(
  portalSettings: PortalSettings,
  status: boolean
) {
  trackEvent(portalSettings, 'Show Complete File Clicked', {
    showCompleteFileStatus: status,
  });
}

export function CopyEvent(portalSettings: PortalSettings, from: string) {
  trackEvent(portalSettings, `${from} Copied`, {});
}

export function AuthAccordianInteractionEvent(
  portalSettings: PortalSettings,
  status: boolean
) {
  trackEvent(portalSettings, 'Docs Authentication Section Interacted', {
    AuthComponentStatus: status ? 'Open' : 'Close',
  });
}

export function ContentTypeInteractionEvent(portalSettings: PortalSettings) {
  trackEvent(portalSettings, 'Content Type Interacted', {});
}

export function TabsInteractionEvent(
  portalSettings: PortalSettings,
  tab: string
) {
  const tabList = ['Request', 'Response', 'Headers', 'Body'];
  const message = tabList.includes(tab)
    ? `Code Sample - ${tab} Tab Clicked`
    : 'Response Example Tab Changed';
  trackEvent(portalSettings, message, {});
}

export function CodeSamplesBackButtonInteractionEvent(
  portalSettings: PortalSettings
) {
  trackEvent(portalSettings, `Code Sample - Back Button Clicked`, {});
}

export function CodeSampleResponseDownloadEvent(
  portalSettings: PortalSettings
) {
  trackEvent(portalSettings, `Code Sample - Response Downloaded`, {});
}
