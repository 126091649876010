import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Element, scroller, animateScroll } from 'react-scroll';
import { PortalSettings } from './PortalSettings';
import { withPortalContext } from './PortalContext';
import { createPath } from 'history';

export interface RouteToScrollSyncProps extends RouteComponentProps<object> {
  containerId: string;

  portalSettings: PortalSettings;
  selectedStepName?: string;
}

const PADDING_TOP = 40;

/**
 * Listens for route changes and jumps to scroll elements whose name matches route pathname
 */
export const RouteToScrollSync = withRouter(
  withPortalContext(
    class RouteToScroll extends React.Component<
      React.PropsWithChildren<RouteToScrollSyncProps>
    > {
      _removeTimer?: NodeJS.Timeout;

      handleScrollToElement() {
        const containerElement = document.getElementById(
          this.props.containerId
        );
        if (containerElement) {
          const path = createPath(this.props.location);
          const containerId = this.props.containerId;
          
          // TODO: Find a better way to wait for the content to render. I believe the headings
          // we need to scroll to render later than expected because we're using React context
          // which causes multiple renders.
          this._removeTimer = setTimeout(
            () =>
              scroller.scrollTo(path, {
                containerId: containerId,
                isDynamic: true,
                offset: -PADDING_TOP,
                smooth: 'easeInOutQuint',
                duration: 250,
              }),
            100
          );
        }
      }

      scrollToTop = () => {
        animateScroll.scrollToTop({
          containerId: this.props.containerId,
          duration: 200,
        });
      };

      componentDidUpdate(prevProps: RouteToScrollSyncProps) {
        if (this.props.location !== prevProps.location) {
          this.handleScrollToElement();
        } else if (this.props.selectedStepName !== prevProps.selectedStepName) {
          this.scrollToTop();
        }
      }

      componentDidMount() {
        this.handleScrollToElement();
      }

      componentWillUnmount() {
        if (this._removeTimer) {
          clearTimeout(this._removeTimer);
        }
      }

      render() {
        return this.props.children;
      }
    }
  )
);

/**
 * An element that can be scrolled to
 */
export function ScrollElement(props: {
  name: string;
  children: React.ReactNode;
}) {
  return <Element name={props.name}>{props.children}</Element>;
}

/**
 * A container that contains scroll elements and that is scrolled when those
 * elements need focus
 */
export function ScrollContainer(props: {
  id: string;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <Element id={props.id} name={props.id} className={props.className}>
      {props.children}
    </Element>
  );
}
