import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ExpandIcon = (props: SVGIconProps) => {
  const { stroke = '#6b7e8f' } = props;

  return (
    <SVGIcon
      width="15.311"
      height="15.311"
      viewBox="0 0 15.311 15.311"
      {...props}
    >
      <g transform="translate(-1113.344 -427.743)">
        <g transform="translate(1111.094 425.493)">
          <path
            d="M15,3h6V9"
            transform="translate(-4.189)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M9,21H3V15"
            transform="translate(0 -4.189)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            x1="5"
            y2="5"
            transform="translate(10.906 3.906)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            y1="5"
            x2="5"
            transform="translate(3.906 10.906)"
            fill="none"
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </SVGIcon>
  );
};
