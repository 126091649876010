import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ChevronDownIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      /* eslint-disable max-len */
      d="M242.051,617.82l-3.865-3.774a.6.6,0,0,1,0-.869.64.64,0,0,1,.89,0l3.419,3.339,3.419-3.339a.64.64,0,0,1,.89,0,.6.6,0,0,1,0,.869l-3.864,3.774a.64.64,0,0,1-.89,0Z"
      transform="translate(-238.002 -612.997)"
    />
  </SVGIcon>
);
