import { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Div, H1 } from './CleanSlate';

import {
  getSteps,
  getWorkflowStepsState,
  WorkflowContextType,
  WorkflowState,
} from './Context/WorkflowContext';
import { TitlePortal } from './Header/Title';
import { guidedWalkThroughEvents } from './Analytics';
import { PortalContext } from './PortalContext';

interface WorkflowContentProps {
  workflowSteps: WorkflowState['string'];
  setVerified: WorkflowContextType['setVerified'];
  workflowName?: string;
}

const WorlkflowContainer = styled(Div)`
  height: 100%;
  width: 100%;
  position: relative;
`;

const WorlkflowContentWrapper = styled(Div)<{ isMarkdownPage?: boolean }>`
  padding: ${({ isMarkdownPage }) =>
    isMarkdownPage ? '0 25px 40px 25px' : 'none'};
  width: 100%;
  max-width: 950px;
  float: right;

  @media only screen and (min-width: 1500px) {
    padding: ${({ isMarkdownPage }) =>
      isMarkdownPage ? '0 40px 40px' : 'none'};
  }
  @media only screen and (max-width: 1500px) {
    padding: ${({ isMarkdownPage }) =>
      isMarkdownPage ? '0 30px 40px' : 'none'};
  }
  @media only screen and (max-width: 1350px) {
    padding: ${({ isMarkdownPage }) =>
      isMarkdownPage ? '0 20px 40px 20px' : 'none'};
  }
  @media only screen and (max-width: 1100px) {
    padding: ${({ isMarkdownPage }) =>
      isMarkdownPage ? '0 20px 40px 20px' : 'none'};
  }
  @media only screen and (max-width: 990px) {
    padding: ${({ isMarkdownPage }) =>
      isMarkdownPage ? '0 80px 40px' : 'none'};
  }
`;

const WorkflowInnerContent = styled(Div)``;

const WALKTHROUGH_OPENED_STEP = 'Guided Walkthrough Opened';

function WorkflowContent(props: WorkflowContentProps) {
  const { workflowSteps, setVerified, workflowName } = props;
  const { selectedStepValue: { name, stepCallback } = {} } =
    getSteps(workflowSteps);
  const stepsState = useRef(getWorkflowStepsState(workflowSteps));
  const [contentJsx, setContentJsx] = useState<JSX.Element>();
  const [isMarkdownPage, setIsMarkdownPage] = useState<boolean>();

  const portalSettings = useContext(PortalContext);

  useEffect(() => {
    if (workflowName && portalSettings) {
      guidedWalkThroughEvents(
        WALKTHROUGH_OPENED_STEP,
        portalSettings,
        workflowName
      );
    }
  }, [workflowName, portalSettings]);

  useEffect(() => {
    stepCallback?.(stepsState.current).then((jsx) => {
      const isContent = !!jsx.props.source;
      if (isContent && workflowName) {
        setVerified(workflowName, isContent);
      }
      setIsMarkdownPage(isContent);
      setContentJsx(jsx);
    });
  }, [stepCallback, setVerified, workflowName]);

  useEffect(() => {
    stepsState.current = getWorkflowStepsState(workflowSteps);
  }, [workflowSteps]);

  return (
    <WorlkflowContainer>
      <TitlePortal>
        <H1>{name}</H1>
      </TitlePortal>
      <WorlkflowContentWrapper isMarkdownPage={isMarkdownPage}>
        <WorkflowInnerContent>{contentJsx}</WorkflowInnerContent>
      </WorlkflowContentWrapper>
    </WorlkflowContainer>
  );
}

export default WorkflowContent;
