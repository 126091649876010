import styled, { css } from 'styled-components';
import { Section } from '../DxDom';
import { Div, Span, P } from '../CleanSlate';
import ContentTable from './ContentTable';
import { DocumentIcon } from '../Icons/Ui/DocumentIcon';
import { CrossIcon } from '../Icons/Ui/CrossIcon';
import { ArrowUpSmall } from '../Icons/Ui/ArrowUpSmall';
import { RefObject, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Scrollable } from 'src/StyledElements';

export type TOCProps = {
  section?: Section;
  hasWorkFlow?: boolean;
  sideContainerRef?: RefObject<HTMLDivElement>;
  activeList: ListObjects[];
};

export interface ListObjects {
  heading: string;
  href: string;
}

const TOCParent = styled(Div)`
  position: relative;
  top: 0px;
  display: flex;
  z-index: 2;

  @media screen and (max-width: 990px) {
    top: 23px;
  }
`;

interface CollapseProps {
  isOpen: boolean;
  docsHeight?: string;
  sideContainerWidth: number;
}

const Collapse = styled(Div)<CollapseProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: ${(props) => (props.isOpen ? '366px' : '6px')};
  transition: right 0.3s;
  width: 40px;
  height: 40px;
  background-color: ${(props) =>
    props.isOpen ? props.theme.colors.C700 : props.theme.colors.C001};
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.C400};
  border: ${(props) =>
    props.isOpen ? 'none' : `1px solid ${props.theme.colors.C300}`};
  border-radius: 4px 0px 0px 4px;
  opacity: 1;

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 991px) {
    display: none;
  }
`;

const CrossContainer = styled(Div)`
  width: 15px;
  height: 17px;

  &:hover {
    cursor: pointer;
  }
`;
export const TableOfContentsContainer = styled(Scrollable)<CollapseProps>`
  position: absolute;
  width: calc(100% + 21px);
  height: fit-content;
  max-height: ${(props) => props.docsHeight};
  padding: 24px 0px 0px 20px;
  overflow: hidden;

  &:hover {
    overflow-y: scroll;
  }

  @media screen and (min-width: 990px) {
    left: -20px;
  }

  @media screen and (max-width: 990px) {
    overflow-y: auto;
    position: fixed;
    background-color: ${(props) => props.theme.colors.C001};
    padding-left: ${(props) => (props.isOpen ? '20px' : '0px')};
    box-shadow: ${(props) =>
      props.isOpen ? `0px 3px 6px ${props.theme.colors.C400}` : `none`};
    border: ${(props) =>
      props.isOpen ? `1px solid ${props.theme.colors.C300}` : 'none'};
    border-radius: 0px 0px 0px 8px;
    opacity: 1;
    width: ${(props) => (props.isOpen ? '360px' : '0px')};
    transition: width 0.3s;
    max-width: none;
    right: 6px;
  }
`;

const TocHeadingContainer = styled(Div)`
  margin: 0px;
  padding: 0px 0px 10px 0px;
  @media screen and (max-width: 990px) {
    border-left: none;
  }
`;

const TOCHeading = styled(Span)`
  color: ${(props) => props.theme.colors.C1100};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.2px;
`;

const backToTopMixin = css`
  color: ${(props) => props.theme.colors.C1100};
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`;
const BackToTopText = styled(P)`
  ${backToTopMixin};
  padding: 0px 0px 0px 5px;
  margin: 0px;
`;

const BackToTop = styled(Div)`
  ${backToTopMixin};
  display: flex;
  margin: 11px 0px 5px 0px;
  align-items: center;
  font-size: 12px;
`;

export default function TableOfContent({
  section,
  hasWorkFlow,
  sideContainerRef,
  activeList,
}: TOCProps) {
  const [isTOCOpen, setIsTOCOpen] = useState<boolean>(false);
  const [docsHeight, setDocsHeight] = useState<number>(0);
  const [sideContainerWidth, setSideContainerWidth] = useState(0);

  const onButtonClick = () => {
    setIsTOCOpen(!isTOCOpen);
  };

  const backToTop = () => {
    let node = document.getElementById('scroll-container');
    let domNode = ReactDOM.findDOMNode(node) as HTMLDivElement;
    if (window.innerWidth < 990) {
      node = document.getElementById('app-layout-docs-container');
      domNode = ReactDOM.findDOMNode(node) as HTMLDivElement;
    }
    domNode.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const tocNode = document.getElementById('toc-container');
    const tocDomNode = ReactDOM.findDOMNode(tocNode) as HTMLDivElement;
    tocDomNode.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const resizeCallback = () => {
    const appLayout = document.getElementById('app-layout-docs-container');
    const domNodeAppLayout = ReactDOM.findDOMNode(appLayout) as HTMLDivElement;
    setDocsHeight(domNodeAppLayout.offsetHeight);
  };

  const hasNoHeading = () => {
    const { Nodes } = section || {};

    const filteredNodes = (Nodes as Section[]).filter((node) => {
      return node.Nodes || node.Title;
    });

    return filteredNodes.length === 0;
  };

  const updateWidth = () => {
    if (sideContainerRef?.current) {
      setSideContainerWidth(sideContainerRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    const appLayout = document.getElementById('app-layout-docs-container');
    const domNodeAppLayout = ReactDOM.findDOMNode(appLayout) as HTMLDivElement;

    if (domNodeAppLayout) {
      setDocsHeight(domNodeAppLayout.offsetHeight);
    }

    window.addEventListener('resize', resizeCallback);

    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  const suggestedLinkString = section?.SuggestedLink || '';
  const isCustomPage = suggestedLinkString?.includes('page:');
  const isGettingStartedPage = suggestedLinkString?.includes('getting_started');
  const shouldDisplayToc =
    section &&
    !hasNoHeading() &&
    (isCustomPage || isGettingStartedPage) &&
    !hasWorkFlow;

  return shouldDisplayToc ? (
    <TOCParent>
      <Collapse
        onClick={onButtonClick}
        isOpen={isTOCOpen}
        sideContainerWidth={sideContainerWidth}
      >
        {isTOCOpen ? (
          <CrossContainer>
            <CrossIcon />
          </CrossContainer>
        ) : (
          <DocumentIcon />
        )}
      </Collapse>
      <TableOfContentsContainer
        id="toc-container"
        isOpen={isTOCOpen}
        docsHeight={`${docsHeight - 100}px`}
        sideContainerWidth={sideContainerWidth}
        scrollWidth="4px"
      >
        {(isTOCOpen || window.innerWidth > 990) && (
          <TocHeadingContainer>
            <TOCHeading>Table Of Contents</TOCHeading>
          </TocHeadingContainer>
        )}
        <ContentTable
          Node={section}
          level={0}
          activeList={activeList}
          sideContainerWidth={sideContainerWidth}
        />
        <BackToTop onClick={backToTop}>
          <ArrowUpSmall />
          <BackToTopText>Back to top</BackToTopText>
        </BackToTop>
      </TableOfContentsContainer>
    </TOCParent>
  ) : (
    <></>
  );
}
