export const StopIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="18.5"
        height="18.5"
        rx="9.25"
        stroke="#6B7E8F"
        strokeWidth="1.5"
      />
      <rect x="6" y="6" width="8" height="8" rx="2" fill="#6B7E8F" />
    </svg>
  );
};
