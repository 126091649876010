import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const RequiredIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M15.998 0c-8.834 0-15.998 7.163-15.998 16 0 8.834 7.163 16 15.998 16 8.837 
      0 16.002-7.166 16.002-16 0-8.837-7.166-16-16.002-16zM15.998 26.213c-1.25 0-2.105-0.965-2.105-2.213 
      0-1.287 0.891-2.215 2.105-2.215 1.285 0 2.107 0.928 2.107 2.215 0 1.248-0.823 2.213-2.107 
      2.213zM16.827 17.76c-0.318 1.086-1.321 1.106-1.657 0-0.386-1.273-1.753-6.114-1.753-9.255 
      0-4.149 5.186-4.167 5.186 0 0 3.161-1.442 8.126-1.776 9.255z"
    />
  </SVGIcon>
);
