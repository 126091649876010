/**
 * A collection of reusable generic components
 */
import styled, { css, keyframes } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { ThemeInterface } from './StyledComponents';
import {
  Div,
  Span,
  InlineCode,
  Em,
  Strong,
  Pre,
  Button,
  ButtonMixin,
  blockCodeMixin,
  anchorMixin,
  H2,
  P,
} from './CleanSlate';
import { darken, readableColor } from 'polished';
export const getContrastColor = (theme: ThemeInterface) => {
  const { primaryColor, colors } = theme;
  if (primaryColor === '#0c7ff2') {
    return colors.C000;
  }
  return readableColor(primaryColor, colors.C900, colors.C000);
};

function hexToRgb(hex: string) {
  /* 
    The function will convert HEX to RGB. Both Full form and Shorthand 
    HEX is been handled but Shorthandis not considered as the conversion
    from HEX to RGB will not be accurate.
    e.g. Use #FFFFF insted of #FFF as the conversion
    from HEX to RGB will not be accurate.
  */
  const hexShort = /^#([0-9a-f])([0-9a-f])([0-9a-f])$/i;

  const hexLong = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

  const hexRegx = hex.length > 4 ? hexLong : hexShort;

  const result = hexRegx.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const getBorderColor = (theme: ThemeInterface) => {
  const { primaryColor, colors } = theme;
  return getContrastColor(theme) === colors.C000
    ? 'transparent'
    : darken(0.1, primaryColor);
};

export const Container = styled(Div)`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
`;
Container.displayName = 'Container';

export const FlexParentMixin = css`
  display: flex;
  flex-direction: ${(props: { type?: 'row' | 'column' }) =>
    props.type || 'row'};
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
`;

export const FlexParent = styled(Div)`
  ${FlexParentMixin};
`;
FlexParent.displayName = 'FlexParent';

export const FlexItem = styled(Div)`
  flex: ${(props: { flex?: number }) => props.flex || 'none'};
`;

type ScrollableProps = {
  scrollWidth?: string;
  invert?: boolean;
  scrollHeight?: string;
  overflowX?: boolean;
  overflowY?: boolean;
};

export const ScrollableMixinInner = css`
  &::-webkit-scrollbar {
    width: ${(props: ScrollableProps) => props.scrollWidth || '4px'};
    height: ${(props: ScrollableProps) => props.scrollHeight || '4px'};
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props: ScrollableProps & { theme: ThemeInterface }) =>
      props.invert ? props.theme.colors.C900 : props.theme.colors.C300};
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  &:hover,
  &:focus {
    &::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background: ${(props: ScrollableProps & { theme: ThemeInterface }) =>
        props.invert ? props.theme.colors.C801 : props.theme.colors.C400};
    }
  }

  @media screen and (max-width: 990px) {
    &::-webkit-scrollbar-thumb {
      background: ${(props: ScrollableProps & { theme: ThemeInterface }) =>
        props.invert ? props.theme.colors.C801 : props.theme.colors.C400};
    }
  }
`;

export const ScrollableMixin = css`
  overflow-y: ${(props: ScrollableProps) =>
    props.overflowY === false ? 'hidden' : 'auto'};
  overflow-x: ${(props: ScrollableProps) =>
    props.overflowX ? 'auto' : 'hidden'};
  -webkit-overflow-scrolling: touch;
  ${ScrollableMixinInner}
`;

export const InvisibleScrollableMixin = css`
  ${ScrollableMixin} {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      height: 0px !important;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: none;
    }
    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }

    &:hover,
    &:focus {
      &::-webkit-scrollbar-thumb {
        background: none;
      }
    }
  }
`;

export const InvertedScrollableMixin = css`
  overflow-y: ${(props: ScrollableProps) =>
    props.overflowY === false ? 'hidden' : 'auto'};
  overflow-x: ${(props: ScrollableProps) =>
    props.overflowX ? 'auto' : 'hidden'};

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #162033;
  }

  &::-webkit-scrollbar-thumb {
    background: #3d4c6a;
  }
`;

export const Scrollable = styled(Div)<ScrollableProps>`
  ${ScrollableMixin};
`;
Scrollable.displayName = 'Scrollable';

export const InvertedScrollable = styled(Div)`
  ${InvertedScrollableMixin};
`;
InvertedScrollable.displayName = 'InvertedScrollable';

export const Overlay = styled(Div)`
  width: 100%;
  height: 100%;
  background-color: ${(props: {
    direction: 'row' | 'column';
    opaque?: boolean;
  }) => (props.opaque ? 'transparent' : 'rgba(255, 255, 255, 0.3)')};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;

  flex-direction: ${(props: {
    direction: 'row' | 'column';
    opaque?: boolean;
  }) => props.direction};
  justify-content: center;
  align-items: center;

  ${H2}, ${P} {
    padding: 0;
    margin: 0;
  }
`;
Overlay.displayName = 'Overlay';

export const TypeWrapper = styled(Div)`
  & a {
    ${anchorMixin};
  }
  & a ${InlineCode} {
    padding-bottom: 1px;
    color: ${(props) => props.theme.linkColor};
  }
  &.reference-modal-fields {
    ul {
      margin: 5px 0px;
    }
  }

  .model-table-link {
    display: inline;

    > a {
      > code {
        padding: 1px 0;
      }
    }
  }
`;

interface ExpandableCodeBlockProps {
  isExpanded?: boolean;
}

export const CodeBlock = styled(FlexParent)`
  width: 100%;
  height: 100%;
  position: relative;
`;
CodeBlock.displayName = 'ExpandableCodeBlock';

const ScaleInCenterKeyframes = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Putting these 2 here for the lack of a better place. :(
export const ExpandableCodeBlock = styled(FlexParent)<ExpandableCodeBlockProps>`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${(props) => props.theme.colors.C900};
  z-index: 7;
  border-radius: 8px;

  @media screen and (max-width: 990px) {
    z-index: 0;
  }

  #outsideClickWrapper {
    width: 100%;
  }

  &.ExpandedCodeSample {
    width: 80%;
    height: 80%;
    z-index: 999999;
    max-width: 1150px;
    animation: ${ScaleInCenterKeyframes} 0.3s cubic-bezier(0.23, 1, 0.32, 1)
      both;

    @media screen and (max-width: 990px) {
      display: none;
    }
  }
`;
ExpandableCodeBlock.displayName = 'CodeBlock';

export const CodeBlockCode = styled(Scrollable)`
  ${blockCodeMixin};
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & pre {
    background: transparent !important;
    border: none;
    word-break: normal;
    overflow: visible !important;
  }

  & pre > code {
    white-space: unset !important;
    padding-right: 16px;
  }
`;
CodeBlockCode.displayName = 'CodeBlockCode';

/**
 *  Alert box
 */

type AlertTypes = 'Info' | 'Warning' | 'Danger';

const alertStyles: {
  [key in AlertTypes]: { bg: string; fg: string; bc: string };
} = {
  Info: {
    bg: '#d9edf7',
    bc: '#9acfea',
    fg: '#31708f',
  },
  Warning: {
    bg: '#fcf8e3',
    bc: '#faebcc',
    fg: '#8a6d3b',
  },
  Danger: {
    bg: '#f2dede',
    bc: '#ebccd1',
    fg: '#a94442',
  },
};

export type AlertProps = { type: AlertTypes };

export const Alert = styled(Div)`
  padding: 15px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  color: ${({ type }: AlertProps) => alertStyles[type].fg};
  background-color: ${({ type }: AlertProps) => alertStyles[type].bg};
  background-repeat: repeat-x;
  border-color: ${({ type }: AlertProps) => alertStyles[type].bc};

  ${Em} {
    color: ${({ type }: AlertProps) => alertStyles[type].fg};
  }

  ${Strong} {
    color: ${({ type }: AlertProps) => alertStyles[type].fg};
  }
`;
Alert.displayName = 'Alert';

export const DangerLabel = styled(Span)`
  color: #ff4451;
  font-size: 10px;
  font-weight: 400;
  padding: 0;
  word-break: break-word;
`;

DangerLabel.displayName = 'DangerLabel';

export const Param = styled(Span)`
  display: inline-flex;
  align-items: center;
  padding: 0;
  word-break: break-word;
  font-family: ${({ theme }) => theme.cssStyles.code.inlineCode.fontFamily};
`;

Param.displayName = 'Param';

export const ParamName = styled(Param)`
  font-size: ${({ theme }) => theme.cssStyles.body.text2.fontSize};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.C900};
`;

ParamName.displayName = 'ParamName';
export const ParamType = styled(Param)<{ marginLeft?: string }>`
  display: inline-flex;
  position: relative;
  align-items: center;
  color: ${({ theme }) => theme.colors.C700};
  font-size: ${({ theme }) => theme.cssStyles.body.text2.fontSize};
  font-weight: 400;
  margin-left: ${(props) => props.marginLeft};
  padding-left: ${(props) => props.marginLeft};

  ${({ theme: { colors }, marginLeft }) =>
    marginLeft &&
    `
  &::before {
    position: absolute;
    width: 1px;
    height: 60%;
    background-color: ${colors.C700};
    content: '';
    display: block;
    left: 1px;
    top: 21%;
  }`};
`;

ParamType.displayName = 'ParamType';

/**********************
 * Button Styles Starts
 **********************/

export const DefaultButtonMixin = css`
  ${ButtonMixin} background: #fff;
  color: ${(props) => props.theme.colors.C800}!important;
  border-color: ${(props) => props.theme.colors.C300 || '#E7E9F2'} !important;
`;

export const PrimaryButtonMixin = css`
  ${ButtonMixin}

  /* theme color variables to use in RGB declarations */
  --red: ${(props) => hexToRgb(props.theme.primaryColor)?.r};
  --green: ${(props) => hexToRgb(props.theme.primaryColor)?.g};
  --blue: ${(props) => hexToRgb(props.theme.primaryColor)?.b};

  /*
    the threshold at which colors are considered "light". 
    Range: decimals from 0 to 1,
    recommended 0.5 - 0.6

    Any lightness value above the threshold will be 
    considered "light", therefore apply a black text 
    color. Any bellow will be considered dark, 
    and use white color. 
  */
  --threshold: 0.5;

  background: ${(props) => props.theme.primaryColor};

  /* 
    Calcs perceived brightness using the 
    sRGB Luma method
    lightness = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255
  */
  --r: calc(var(--red) * 0.2126);
  --g: calc(var(--green) * 0.7152);
  --b: calc(var(--blue) * 0.0722);
  --sum: calc(var(--r) + var(--g) + var(--b));

  --perceived-lightness: calc(var(--sum) / 255);

  color: hsl(
    0,
    0%,
    calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
  );

  &:hover,
  &:focus {
    color: hsl(
      0,
      0%,
      calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
    );
  }
`;

export const DarkButtonMixin = css`
  ${ButtonMixin}
  background: ${(props) => props.theme.colors.C801 || '#0058a9'} !important;
  color: ${(props) => props.theme.colors.C001};
  border-color: ${(props) => props.theme.colors.C801 || '#0058a9'} !important;
`;
export const DefaultButton = styled(Button)`
  ${DefaultButtonMixin};
`;
export const PrimaryButton = styled(Button)`
  ${PrimaryButtonMixin};
`;
PrimaryButton.displayName = 'PrimaryButton';
export const DarkButton = styled(Button)`
  ${DarkButtonMixin};
`;
DarkButton.displayName = 'DarkButton';

export const LinkButton = styled(Button)`
  color: ${(props) => props.color};
  :hover {
    opacity: 0.7;
  }
`;

type IconButtonProps = {
  width?: string;
  varient?: string | 'primary' | 'light' | 'dark' | 'transparent';
};

export const IconButtonMixin = css<IconButtonProps>`
  width: ${(props) => (props.width ? props.width : '32px')};
  height: ${(props) => (props.width ? props.width : '32px')};
  background: ${(props) =>
    props.varient === 'primary'
      ? props.theme.primaryColor
      : props.varient === 'dark'
      ? props.theme.colors.C801
      : props.varient === 'light'
      ? props.theme.colors.C300
      : 'transparent'};
  padding: 0;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    padding: 0;
  }
`;
// IconButton.displayName = 'IconButton';

export const IconButton = styled(Button)`
  ${IconButtonMixin};
`;

// Styled Pre element for use in body of content (where markdown is rendered)
export const StyledPre = styled(Pre)`
  ${ScrollableMixin};
  max-height: 400px;
  overflow: auto;
  border-radius: 8px;
  margin: 20px 0;
  padding: 0px;

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & pre {
    overflow: visible !important;
    margin-bottom: 0 !important;
  }

  & pre > code {
    white-space: pre !important;
  }
`;
StyledPre.displayName = 'StyledPre';

export const ResizeEffectMixin = css`
  transition: all 1s ease-in-out, left 1.5s ease-in-out;
`;

export const BaseShadowMixin = css`
  box-shadow: ${(props) => props.theme.colors.C400} 1px 5px 6px;
`;

/**
 * using TableWrapper to allow scroll on Table
 */

export const TableWrapper = styled(Div)`
  ${ScrollableMixin};
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.colors.C300};
  margin-bottom: 30px;
  border-radius: 8px;
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  > table {
    border: none;
    margin: 0;
    thead {
      border-radius: 4px 4px 0 0;
      background: ${(props) => props.theme.colors.C201};
    }
    thead,
    tbody {
      th,
      td {
        border-bottom: 1px solid ${(props) => props.theme.colors.C300};
        border-radius: 4px 4px 0 0;
      }
      tr:last-child td {
        border-bottom-color: transparent;
      }
    }
  }
  &.enum-table-data {
    td:first-child {
      width: 25%;
    }
  }
`;

export const ContentOverlay = styled(Div)<{ isWorkflowPage: boolean }>`
  width: 100%;
  height: ${(props) => (props.isWorkflowPage ? 'calc(100% - 137px)' : '100%')};
  background: #fff;
  opacity: 0.7;
  position: absolute;
  z-index: 1;
  border-top-left-radius: 8px;
  border-bottom-left-radius: ${(props) =>
    props.isWorkflowPage ? '8px' : 'none'};
`;

export const GetSdkButtonStyleMixin = css`
  min-width: 105px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  padding: 12px;
  height: 30px;

  @media screen and (max-width: 1200px) {
    min-width: 90px;
  }

  @media screen and (max-width: 990px) {
    min-width: 80px;
    padding: 0 11px;
  }

  @media screen and (max-width: 575px) {
    min-width: 70px;
    height: 50px;
    background: transparent !important;
    color: ${(props) => props.theme.colors.C901} !important;
    border: none !important;
    border-left: 4px solid #fff !important;
    border-radius: 0px;
    margin-right: 0;
    padding: 0;
    position: relative;
  }
`;

export const inputFieldsMixin = css`
  width: 100%;
  max-width: 250px;
  border: 1px solid ${({ theme }) => theme.colors.C300};
  margin-top: 5px;
  padding: 0;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.C801};
  font-size: ${({ theme: { cssStyles } }) =>
    (cssStyles && cssStyles.body.text3.fontSize) || '12.64px'};
  line-height: normal;
  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.primaryColor || '#0058a9'};
  }
`;

export const reactDatePickerMixin = css`
  .react-datepicker {
    border: none;
    box-shadow: 0 1px 5px ${(props) => props.theme.colors.C400};
  }
  .react-datepicker__day--selected {
    background-color: ${(props) => props.theme.primaryColor};
    color: ${(props) => getContrastColor(props.theme)};
    font-weight: bold;
  }
  .react-datepicker__time-list-item--selected {
    background-color: ${(props) => props.theme.primaryColor} !important;
    color: ${(props) => getContrastColor(props.theme)} !important;
  }
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    border: none;
    box-shadow: 0 1px 5px ${(props) => props.theme.colors.C400};
    background: ${(props) => props.theme.colors.C001};
  }
  .react-datepicker__navigation {
    border-width: 5.6px;
  }
  .react-datepicker__header {
    background: ${(props) => props.theme.colors.C001};
    border-bottom: 1px solid ${(props) => props.theme.colors.C300};
  }
  .react-datepicker__day-name {
    font-weight: bold;
  }
  .react-datepicker__today-button {
    background: ${(props) => props.theme.colors.C001};
    border-top: 1px solid ${(props) => props.theme.colors.C300};
    border-bottom-left-radius: 4.8px;
    border-bottom-right-radius: 4.8px;

    :hover {
      background: #f0f0f0; // date picker default hover color
    }
  }
  .react-datepicker__time {
    border-bottom-left-radius: 4.8px;
    border-bottom-right-radius: 4.8px;
    height: 100% !important;
  }
  .react-datepicker__time-box {
    height: calc(100% - 43px);
  }
  .react-datepicker__time-list {
    height: 100% !important;
  }
  .react-datepicker__time-container--with-today-button {
    border: none;
    box-shadow: 0 1px 5px ${(props) => props.theme.colors.C400};
    height: 100%;
    overflow: hidden;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-width: 5.6px;
    border-top-color: ${(props) => props.theme.colors.C901};
  }
  .react-datepicker__navigation--next {
    border-left-color: ${(props) => props.theme.colors.C901};
  }
  .react-datepicker__navigation--previous {
    border-right-color: ${(props) => props.theme.colors.C901};
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
    border-top-color: ${(props) => props.theme.colors.C001} !important;
  }
  .react-datepicker__triangle {
    border-bottom-color: ${(props) => props.theme.colors.C001} !important;
  }
`;

export const reactSelectMixin = css`
  .react-select--is-disabled {
    .react-select__value-container {
      background: ${(props) => props.theme.colors.C300};
      .react-select__placeholder {
        color: ${(props) => props.theme.colors.C600};
      }
    }
    .react-select__control {
      border: 1px solid ${(props) => props.theme.colors.C400};
      overflow: hidden;
    }
    .react-select__indicators {
      background: ${(props) => props.theme.colors.C300};

      svg {
        fill: ${(props) => props.theme.colors.C400};
      }

      .react-select__indicator-separator {
        background-color: ${(props) => props.theme.colors.C400};
      }
    }
  }

  .react-select__control {
    height: auto;
    min-height: 32px;
    box-shadow: none;
    ${inputFieldsMixin};
  }

  .react-select__value-container {
    min-height: 32px;
    padding: 2px 8px 2px 12px;
    cursor: text;
    .react-select__placeholder {
      color: ${(props) => props.theme.colors.C801};
    }
  }
  .react-select__indicators {
    cursor: pointer;

    svg {
      width: 15px;
      height: 15px;
      fill: ${(props) => props.theme.colors.C801};
    }

    .react-select__indicator-separator {
      background-color: ${(props) => props.theme.colors.C300};
    }
  }

  .react-select__multi-value {
    .react-select__multi-value__label,
    .react-select__multi-value__remove {
      color: ${(props) => props.theme.colors.C801};
      background-color: ${(props) => props.theme.colors.C201};
    }
    .react-select__multi-value__remove {
      cursor: pointer;
    }
  }

  .react-select__input,
  .react-select__input input {
    height: 20px;
    margin-top: 0;
  }
  .react-select__menu {
    width: 100%;
    max-width: 250px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.C300};
    box-shadow: 0 0 4px ${(props) => props.theme.colors.C300};
    z-index: 100;

    .react-select__menu-list {
      padding: 6px 0;
      color: ${(props) => props.theme.colors.C801}!important;
      .react-select__option {
        min-height: 30px;
        font-size: 14.22px;
        line-height: 18px;
        font-weight: 400;
        padding: 8px 12px;
        cursor: pointer;

        &.react-select__option--is-focused:not(
            .react-select__option--is-selected
          ) {
          color: ${(props) => props.theme.colors.C801}!important;
          background-color: ${(props) => props.theme.colors.C100};
        }
      }
    }
  }
`;

export const ellipsisMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const selectWidgetMixin = css`
  justify-content: center;
  .__title {
    &.--tag {
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 6px;
      margin-bottom: 0;
      padding-top: 8px;
    }
  }
  .__tags-wrapper {
    &.--variant-tag {
      display: none;
    }

    &.--variant-select {
      display: inline-flex;
      align-items: center;
      align-content: center;

      .rjsf-form-control {
        min-width: 250px;
      }

      > div:first-child {
        flex: 1;

        .rjsf-form-control {
          min-width: unset;
        }
      }
    }
  }
`;

export const discriminatorMixin = css`
  .tag-selector {
    display: flex;
    margin-bottom: 15px;
    min-width: 300px;

    &.anyof-child,
    &.object-child {
      margin-bottom: 0;
    }

    &.select-container {
      ${selectWidgetMixin}
    }

    .__tags-wrapper {
      display: inline-flex;
      flex-wrap: wrap;
      flex: 1;

      &.--variant-tag {
        display: inline-flex;
      }

      .tag-selector-button-link {
        svg {
          stroke: #fff;
          top: 2px;
          position: relative;
          pointer-events: none;
        }
      }

      &.--variant-select {
        display: none;
        position: relative;
        .tags-variant-select-link {
          display: flex;
          align-content: center;
          align-items: center;
          margin-left: 15px;
          margin-top: 5px;
          position: absolute;
          left: 250px;
          svg {
            margin-right: 2px;
            position: relative;
            top: 1px;
            stroke: ${(props) => props.theme.linkColor};
            pointer-events: none;
          }
          a {
            font-size: ${({ theme: { cssStyles } }) =>
              (cssStyles && cssStyles.body.text3.fontSize) || '12.64px'};
          }
        }
        @media screen and (max-width: 650px) {
          .tags-variant-select-link {
            position: unset;
            margin: 0px;
          }
        }
      }
    }

    .__title {
      text-transform: uppercase;
      font-weight: bold;
      color: ${(props) => props.theme.colors.C901};
    }

    .__title.--tag {
      border: none;
      align-items: start;
      cursor: default;
      padding-left: 0;
      padding-top: 3px;
      margin-right: 5px;
      color: ${(props) => props.theme.colors.C901};
    }

    .tag-wrapper-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      .tag-label {
        margin-right: 1px;
        &.--active {
          border-radius: 15px 0 0 15px;
        }
        &.--active-with-no-link {
          border-radius: 15px;
        }
      }
      &.tag-wrapper-item-disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    .--tag {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 5px;
      padding: 1px 10px;
      color: ${(props) => props.theme.colors.C700};
      border: 1px solid ${(props) => props.theme.colors.C700};

      cursor: pointer;
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.fontSize) || '11.85px'};

      &.tag-label {
        border-radius: 15px;
      }
      &.tag-link {
        border-radius: 0px 15px 15px 0px;
        display: inline-block;
        position: relative;
        top: -1px;
      }
    }

    .--tag.--active {
      background: ${(props) => props.theme.colors.C700};
      color: ${(props) => props.theme.colors.C001};
    }

    @media (max-width: 1024px) {
      ${selectWidgetMixin}
    }
  }
`;
export const Link = styled(RouterLink)`
  ${anchorMixin};
`;
Link.displayName = 'CS.Link';

export const rotateMixin = (angle: number) => css`
  transform: rotate(${angle}deg);
`;

export const textAreaMixin = css`
  padding: 6px 12px !important;
  min-width: 250px;
  min-height: 100px;
  resize: none;
`;
