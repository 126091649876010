import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ArrowDownIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M25.172 13c0 0.531-0.219 1.047-0.578 1.406l-10.172 10.187c-0.375 0.359-0.891 0.578-1.422 
      0.578s-1.047-0.219-1.406-0.578l-10.172-10.187c-0.375-0.359-0.594-0.875-0.594-1.406s0.219-1.047 
      0.594-1.422l1.156-1.172c0.375-0.359 0.891-0.578 1.422-0.578s1.047 0.219 1.406 0.578l4.594 4.594v-11c0-1.094 
      0.906-2 2-2h2c1.094 0 2 0.906 2 2v11l4.594-4.594c0.359-0.359 0.875-0.578 1.406-0.578s1.047 0.219 1.422 
      0.578l1.172 1.172c0.359 0.375 0.578 0.891 0.578 1.422z"
    />
  </SVGIcon>
);
