import styled from 'styled-components';
import { MaximizeIcon } from '../../Icons/MaximizeIcon';
import { CrossIcon } from '../../Icons/CrossIcon';
import { ResetIcon } from '../../Icons/ResetIcon';
import { useState } from 'react';
import { ConfirmationModal } from '../confirmation-modal/ConfirmationModal';
import { MinimizeIcon } from '../../Icons/MinimizeIcon';
import { HeaderHeight } from '../../typings';

const ChatRoomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: ${HeaderHeight};
  border-radius: 8px 8px 0px 0px;
  background-color: #ebf0f5;
`;

const HeadingTitle = styled.h5`
  color: #28442d;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 20px;
`;

const IconsWrapperParent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-right: 20px;
`;

const IconsWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export interface ChatHeaderProps {
  clearChat: () => void;
  toggleMaximise: () => void;
  toggleChat: () => void;
  isMaximized: boolean;
}

export function ChatHeader({
  clearChat,
  toggleChat,
  toggleMaximise,
  isMaximized,
}: ChatHeaderProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    clearChat();
    setIsModalOpen(false);
  };
  return (
    <>
      <ChatRoomHeader data-testid="api-copilot-header">
        <HeadingTitle>API Copilot</HeadingTitle>
        <IconsWrapperParent>
          <IconsWrapper
            onClick={() => handleOpenModal()}
            data-testid="api-copilot-reset-button"
          >
            <ResetIcon />
          </IconsWrapper>
          {window.innerWidth > 680 && (
            <IconsWrapper
              onClick={() => toggleMaximise()}
              data-testid="api-copilot-maximize-button"
            >
              {isMaximized ? <MinimizeIcon /> : <MaximizeIcon />}
            </IconsWrapper>
          )}
          <IconsWrapper
            onClick={() => toggleChat()}
            data-testid="api-copilot-close-button"
          >
            <CrossIcon />
          </IconsWrapper>
        </IconsWrapperParent>
      </ChatRoomHeader>
      {isModalOpen && (
        <ConfirmationModal
          body="Clear this chat?"
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
}

export default ChatHeader;
