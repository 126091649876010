import { useRef, useState, useEffect } from 'react';
import { Div, P } from 'src/CleanSlate';
import { HandleOutsideClick } from 'src/HandleOutsideClick';
import { EllipsisIcon } from 'src/Icons/Ui/EllipsisIcon';
import styled from 'styled-components';

// Styled components
const BreadcrumbContainer = styled(Div)`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  margin: 18px 0 8px 0;
  white-space: nowrap; // Ensure single line
  line-height: 14.22px;
  font-size: 12px;
  color: ${(props) => props.theme.staticColors.Goose.C200};
`;

const BreadcrumbItem = styled.span`
  &:not(:last-child)::after {
    content: '/';
    margin: 0 6px;
  }
`;

const IconWrapper = styled(Div)`
  display: inline-block;
  position: relative;

  & > div:first-child {
    cursor: pointer;
  }
`;

const HiddenItemsPopover = styled(Div)`
  height: fit-content;
  width: 170px;
  border: 1px solid ${(props) => props.theme.staticColors.Snow.C100};
  position: absolute;
  top: 20px;
  background-color: ${(props) => props.theme.staticColors.Purple.C400};
  border-radius: 8px;
  box-shadow: 0px 2px 2px 0px #00000040;
  z-index: 9;
  padding: 12px;
  display: flex;
  flex-direction: column;

  > div {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    margin-bottom: 12px;
    color: ${(props) => props.theme.colors.C900};

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const Breadcrumb = ({
  path,
  hasWorkFlow,
}: {
  path: string;
  hasWorkFlow?: boolean;
}) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<string[]>([]);
  const [displayItems, setDisplayItems] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const ELLIPSIS_IDENTIFIER = '$...$';

  // Utility function to format breadcrumb text
  const formatBreadcrumbText = (text: string) => {
    const specialWords = ['api', 'oneof', 'anyof'];

    return text
      .split('-')
      .map((word) => {
        const lowerCaseWord = word.toLowerCase();
        if (specialWords.includes(lowerCaseWord)) {
          return lowerCaseWord.toUpperCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  };

  useEffect(() => {
    const pathArray = path.split('/').filter((item) => item); // Split and filter out empty strings
    const formattedItems = pathArray.slice(1, -1).map(formatBreadcrumbText); // Format each item
    setBreadcrumbItems(formattedItems);
  }, [path]);

  useEffect(() => {
    const checkWidth = () => {
      const breadcrumbElement = breadcrumbRef.current;
      const parentElement = breadcrumbElement?.parentElement;

      if (breadcrumbElement && parentElement) {
        const parentStyles = getComputedStyle(parentElement);
        const parentPadding =
          parseFloat(parentStyles.paddingLeft) +
          parseFloat(parentStyles.paddingRight);
        const parentWidth = parentElement.clientWidth - parentPadding;

        if (
          breadcrumbElement.scrollWidth > parentWidth &&
          breadcrumbItems.length > 2
        ) {
          setDisplayItems([
            breadcrumbItems[0],
            ELLIPSIS_IDENTIFIER,
            breadcrumbItems[breadcrumbItems.length - 1],
          ]);
        }
      }
    };

    // Check width initially
    checkWidth();
  }, [breadcrumbItems]);

  const onOutsideClick = () => {
    setShowPopup(false);
  };

  const displayItemsArray = displayItems.length
    ? displayItems
    : breadcrumbItems;
  const hiddenItemsArray = displayItems.length
    ? breadcrumbItems.slice(1, -1)
    : [];

  return (
    <BreadcrumbContainer ref={breadcrumbRef}>
      {!hasWorkFlow &&
        displayItemsArray.map((item, index) => {
          const isEllipsis = item === ELLIPSIS_IDENTIFIER;
          const displayItem = isEllipsis ? (
            <IconWrapper>
              <Div
                onClick={(e) => {
                  setShowPopup(!showPopup);
                }}
                ref={iconRef}
              >
                <EllipsisIcon />
              </Div>
              {showPopup && (
                <HandleOutsideClick
                  onOutsideClick={onOutsideClick}
                  targetRefrance={iconRef}
                >
                  <HiddenItemsPopover>
                    {hiddenItemsArray.map((item, index) => (
                      <Div key={`popup-item-${index}`}>{item}</Div>
                    ))}
                  </HiddenItemsPopover>
                </HandleOutsideClick>
              )}
            </IconWrapper>
          ) : (
            item
          );
          return (
            <BreadcrumbItem key={`breadcrumb-item-${index}`}>
              {displayItem}
            </BreadcrumbItem>
          );
        })}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
