import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const FrownIcon = (props: SVGIconProps) => (
  <SVGIcon viewBox="0 0 24 28" {...props}>
    <path
      d="M17.719 19.203c0.172 0.531-0.125 1.078-0.641 1.25-0.531 
    0.172-1.094-0.125-1.266-0.656-0.516-1.672-2.063-2.797-3.813-2.797s-3.297 
    1.125-3.813 2.797c-0.172 0.531-0.734 0.828-1.25 0.656-0.531-0.172-0.828-0.719-0.656-1.25 
    0.781-2.516 3.078-4.203 5.719-4.203s4.937 1.687 5.719 4.203zM10 10c0 1.109-0.891 
    2-2 2s-2-0.891-2-2 0.891-2 2-2 2 0.891 2 2zM18 10c0 1.109-0.891 2-2 2s-2-0.891-2-2 
    0.891-2 2-2 2 0.891 2 2zM22 14c0-5.516-4.484-10-10-10s-10 4.484-10 10 4.484 10 10
     10 10-4.484 10-10zM24 14c0 6.625-5.375 12-12 12s-12-5.375-12-12 5.375-12 12-12 12 5.375 12 12z"
    />
  </SVGIcon>
);
