import styled from 'styled-components';
import { Div, H6 } from './CleanSlate';
import { ResponseInfo, SectionNode } from './DxDom';
import { ContentBody } from './MultipleResponses/ContentBody';
import { ResponseHeaderTable } from './MultipleResponses/HeaderTable';
import { EnclosedTabs, EnclosedTabPanel } from './EnclosedTabs';
import { Callout } from './MultipleResponses/Callout';
import { PortalSettings } from './PortalSettings';

interface MultipleResponseProps {
  responses: ReadonlyArray<ResponseInfo>;
  renderNode: (node: SectionNode) => JSX.Element;
  portalSettings: PortalSettings;
}

interface CircleIconDef {
  color: string;
}

const LabelWrapper = styled(Div)`
  ${H6} {
    margin-top: 30px;
    margin-bottom: 15px;
  }
`;
LabelWrapper.displayName = 'LabelWrapper';

const TabItemWrapper = styled(Div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
TabItemWrapper.displayName = 'TabItemWrapper';

const CircleIcon = styled(Div)<CircleIconDef>`
  background-color: ${(props) => props.color};
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
`;
CircleIcon.displayName = 'CircleIcon';

function StatusCodeColor({ statusCode }: { statusCode: number }) {
  if (statusCode < 300) {
    return <CircleIcon color="#28C397" />;
  } else if (statusCode < 400) {
    return <CircleIcon color="#FF8A70" />;
  } else if (statusCode < 501) {
    return <CircleIcon color="#FF4451" />;
  } else {
    return <CircleIcon color="#FF4451" />;
  }
}

/**
 * Render an Multiple responses Tabs
 * @param responses DX Endpoint reference response list
 * @param nodeRenderer Renderer for DX section nodes
 */
export function MultipleResponses(props: MultipleResponseProps) {
  const { responses, renderNode, portalSettings } = props;

  const renderTabListItemTemplate = (statusCode: string) =>
    statusCode === '0' ? (
      'Default'
    ) : (
      <TabItemWrapper
        className="tab-list-item-template-wrapper"
        title={statusCode}
      >
        <StatusCodeColor statusCode={parseInt(statusCode, 10)} />
        {statusCode}
      </TabItemWrapper>
    );

  return (
    <div>
      <EnclosedTabs initialOpenKey={responses[0].StatusCode} key="responses">
        {responses.map((resp, index) => (
          <EnclosedTabPanel
            key={resp.StatusCode + index}
            title={resp.StatusCode === '0' ? 'Default' : resp.StatusCode}
            tabListItemTemplate={renderTabListItemTemplate(resp.StatusCode)}
          >
            {resp.Description &&
              renderNode({
                Type: 'paragraph',
                Text: resp.Description,
              })}

            {resp.Headers && resp.Headers.length > 0 && (
              <LabelWrapper>
                <H6>Headers</H6>
                <ResponseHeaderTable headers={resp.Headers} />
              </LabelWrapper>
            )}

            {resp.Content.length > 0 ? (
              <ContentBody
                contentInfoList={resp.Content}
                renderNode={renderNode}
                portalSettings={portalSettings}
              />
            ) : (
              <Callout icon="info">
                There is no response body for this status code.
              </Callout>
            )}
          </EnclosedTabPanel>
        ))}
      </EnclosedTabs>
    </div>
  );
}
