/**
 * A fullText picker component for React.
 *
 * Derived from https://github.com/1egoman/fuzzy-picker/blob/master/src/fuzzy-picker.js
 * See original license at https://github.com/1egoman/fuzzy-picker/blob/master/LICENSE
 */
import { Component, RefObject, createRef } from 'react';
import styled, { keyframes } from 'styled-components';
import lunr from 'lunr';

import { ThemeInterface } from './StyledComponents';
import { Div, Li, Ul, Span } from './CleanSlate';
import { ScrollableMixin } from './StyledElements';
import { NavItemWrapper } from './QuickSearch';

import Color from 'color';
import SearchNavItem from './SearchNavItem';
import {
  SearchDocumentIcon,
  UpDownArrows,
  RightLeftArrows,
} from './Icons/Ui/index';
import { AllSearchSections, NavItem } from './DxDom';
import { staticColors } from './Color';
import { PortalSettings } from './PortalSettings';
import { logEventOnClickingSearchResult } from './Analytics';
import ReactDOM from 'react-dom';

export interface FullTextpickerProps {
  label?: string;
  displayCount?: number;
  cycleAtEndsOfList?: boolean;
  onChangeHighlightedItem?: (choice: UpdatedNavItemWrapper) => void;
  isOpen: boolean;
  onClose?: () => void;
  onChange?: (choice: UpdatedNavItemWrapper) => void;
  items: NavItemWrapper[];
  renderItem?: (
    item: UpdatedNavItemWrapper,
    selected: boolean,
    match: string
  ) => React.ReactNode;
  itemValue?: (item: UpdatedNavItemWrapper) => string;
  itemKey?: (item: UpdatedNavItemWrapper) => string;
}

type FullTextpickerCompProps = FullTextpickerProps & {
  portalSettings: PortalSettings;
};

type UpdatedNavItemWrapper = NavItemWrapper & {
  score: number;
};

interface SearchItems {
  allItems: UpdatedNavItemWrapper[];
  filteredItems: UpdatedNavItemWrapper[];
}
export interface FullTextpickerState {
  selectedIndex: number;
  items: SearchItems;
  index: lunr.Index;
  selectedNavItem: AllSearchSections;
  query: string;
}

export const PopupBackground = styled(Div)<{ isCodeSample?: true }>`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  @media screen and (max-width: 990px) {
    display: ${({ isCodeSample }) => (isCodeSample ? 'none' : 'flex')};
  }
`;

const ScaleInCenterKeyframes = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const SearchContainer = styled(Div)`
  width: 630px;
  max-height: 800px;
  height: 78vh;
  background: ${(props) => props.theme.staticColors.Purple.C400};
  border: 1px solid ${(props) => props.theme.colors.C300};
  border-radius: 4px;
  box-shadow: 0px 0px 16px #333;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  animation: ${ScaleInCenterKeyframes} 0.3s cubic-bezier(0.23, 1, 0.32, 1) both;
`;

const SearchInstructions = styled(Span)`
  color: ${(props) => props.theme.colors.C1100};
  margin: 15px;
  font-size: 14.5px;
  user-select: none;

  @media screen and (max-width: 990px) {
    display: none;
  }
`;
const SearchInput = styled.input`
  color: ${(props) => props.theme.colors.C800};
  border-radius: 8px;
  outline: none;
  flex: 1;
  font-size: 18px;
  padding: 8px 16px;
  margin: 0 15px 15px;
  border: 1px solid ${(props) => props.theme.colors.C300};
  max-height: 46px;

  @media screen and (max-width: 990px) {
    margin-top: 15px;
  }
`;

const SearchListItem = styled(Li)`
  margin: 0px 8px 8px 8px;
  padding: 5px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;

  ${(props: { selected: boolean; theme?: ThemeInterface }) =>
    props.selected
      ? 'background-color: ' +
        Color('#333333')
          .mix(Color(props.theme ? props.theme.primaryColor : '#0058a9'), 0.5)
          .lightness(40)
          .toString() +
        '; color: #fff;'
      : ''};
`;

const Line = styled(Div)`
  border: 1px solid ${(props) => props.theme.staticColors.Snow.C300};
  width: 94.5%;
  margin-left: 17px;
`;

const SearchNavContainer = styled(Div)`
  border-bottom: none;
  width: 598px;
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 38px;
`;

const SearchListContainer = styled(Div)`
  border: 1px solid ${(props) => props.theme.colors.C300};
  border-radius: 0px 0px 8px 8px;
  margin: 15px;
  margin-top: 0px;
  height: 80%;
`;

const SearchList = styled(Ul)`
  min-height: 0;
  max-height: 100%;
  list-style-type: none;
  padding-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  ${ScrollableMixin};
`;

const ListItemContainer = styled(Div)`
  padding-top: 8px;
`;
const EmptyListContainer = styled(Div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SearchMessage = styled(Div)<{ isError: boolean }>`
  font-size: 15px;
  color: ${(props) =>
    props.isError
      ? props.theme.staticColors.Red.C000
      : props.theme.staticColors.Goose.C200};
  padding-top: 10px;
`;

export class FullTextPicker extends Component<
  FullTextpickerCompProps,
  FullTextpickerState
> {
  private inputRef: RefObject<HTMLInputElement>;
  private searchListRef: RefObject<HTMLUListElement>;

  constructor(props: FullTextpickerCompProps) {
    super(props);
    this.state = {
      selectedIndex: 0, // which item is selected?
      items: {
        allItems: [],
        filteredItems: [], // the items wich are displayed in the fullText find list
      },
      index: lunr(function () {
        this.field('Text');
        this.ref('Link');
        this.pipeline.remove(lunr.stopWordFilter);
        props.items.forEach((doc) => {
          this.add(doc.item);
        });
      }),
      selectedNavItem: 'All',
      query: '',
    };
    this.inputRef = createRef();
    this.searchListRef = createRef();
  }

  // Scroll list into view on key up and down
  scrollIntoView(selectedIndex: number) {
    const listNode = this.searchListRef.current;
    if (listNode) {
      listNode!.children[selectedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }

  // Move the selected index up or down.
  onMoveUp() {
    if (this.state.selectedIndex > 0) {
      this.selectIndex(this.state.selectedIndex - 1);
      this.scrollIntoView(this.state.selectedIndex - 1);
      // User is at the start of the list. Should we cycle back to the end again?
    } else if (
      this.props.cycleAtEndsOfList ||
      this.props.cycleAtEndsOfList === undefined
    ) {
      this.selectIndex(this.state.items.filteredItems.length - 1);
      this.scrollIntoView(this.state.items.filteredItems.length - 1);
    }
  }

  onMoveDown() {
    const itemsLength = this.state.items.filteredItems.length - 1;
    if (this.state.selectedIndex < itemsLength) {
      this.selectIndex(this.state.selectedIndex + 1);
      this.scrollIntoView(this.state.selectedIndex + 1);
      // User is at the end of the list. Should we cycle back to the start again?
    } else if (
      this.props.cycleAtEndsOfList ||
      this.props.cycleAtEndsOfList === undefined
    ) {
      this.selectIndex(0);
      this.scrollIntoView(0);
    }
  }

  onMoveRightOrLeft = (direction: 'Right' | 'Left') => {
    const tabsArray: AllSearchSections[] = [
      'All',
      'API Endpoints',
      'Models',
      'Documentation',
    ];
    const indexOfCurrentTab = tabsArray.indexOf(this.state.selectedNavItem);
    const isRightArrow = direction === 'Right';
    const arrayLength = tabsArray.length;

    const indexOfNextTab = isRightArrow
      ? this.indexForRightArrow(indexOfCurrentTab, arrayLength)
      : this.indexForLeftArrow(indexOfCurrentTab, arrayLength);

    this.onNavItemClick(tabsArray[indexOfNextTab]);
  };

  indexForRightArrow(indexOfCurrentTab: number, arrayLength: number) {
    const isLastTab = indexOfCurrentTab + 1 === arrayLength;
    return isLastTab ? 0 : indexOfCurrentTab + 1;
  }

  indexForLeftArrow(indexOfCurrentTab: number, arrayLength: number) {
    const isfirstTab = indexOfCurrentTab === 0;
    return isfirstTab ? arrayLength - 1 : indexOfCurrentTab - 1;
  }

  // handle key events in the textbox
  onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      // Moving up and down
      // Either arrow keys, tab/shift+tab, or ctrl+j/ctrl+k (what's used in vim sometimes)
      case 'ArrowUp': {
        this.onMoveUp();
        event.preventDefault();
        break;
      }
      case 'ArrowDown': {
        this.onMoveDown();
        event.preventDefault();
        break;
      }
      case 'ArrowRight': {
        this.onMoveRightOrLeft('Right');
        event.preventDefault();
        break;
      }
      case 'ArrowLeft': {
        this.onMoveRightOrLeft('Left');
        event.preventDefault();
        break;
      }
      case 'k': {
        if (event.ctrlKey) {
          this.setState({
            items: this.getInitialItems(),
            query: '',
            selectedNavItem: 'All',
          });
        }
        break;
      }
      case 'Tab': {
        if (event.shiftKey) {
          this.onMoveUp();
        } else {
          this.onMoveDown();
        }
        event.preventDefault();
        break;
      }

      case 'Enter': {
        // Enter key
        if (this.state.items.filteredItems.length) {
          const item = this.state.items.filteredItems[this.state.selectedIndex];
          const { Text, Link } = item.item;
          if (item) {
            logEventOnClickingSearchResult(
              this.props.portalSettings,
              this.state.query,
              Text || '',
              Link
            );
            this.setState({
              items: this.getInitialItems(),
              query: '',
              selectedNavItem: 'All',
            });
            if (this.props.onChange) {
              this.props.onChange(item);
            }
          }
        }

        break;
      }
      case 'Escape': {
        this.setState({
          items: this.getInitialItems(),
          query: '',
          selectedNavItem: 'All',
        });
        if (this.props.onClose) {
          this.props.onClose();
        }
        break;
      }
      default:
    }
  };

  getInitialItems(): FullTextpickerState['items'] {
    return { allItems: [], filteredItems: [] };
  }

  //This is not a permanant solution. Just a quick fix to go on with the release.
  queryModifier = (query: string) => {
    const specialCharacterReplacement = '_>#<_';
    // Replace "-" with " "
    let modifiedStr = query.replace(/-/g, ' ');

    // Replace "-", "+", "~", "^", or "*" with the replacement.
    modifiedStr = modifiedStr.replace(/[+~^*:]/g, specialCharacterReplacement);

    return modifiedStr.trim();
  };

  // When the user types into the textbox, this handler is called.
  // Though the textbox is an uncontrolled input, this is needed to regenerate the
  // list of choices under the textbox.
  onInputChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const query = this.queryModifier(ev.target.value);
    this.setState((prevStates) => ({ ...prevStates, query }));
    if (query.length) {
      const updatedQuery = `${query} ${query}*`;

      // Pick the closest matching items if possible.
      const results = this.state.index.search(updatedQuery);
      const resultsInItemProps = this.props.items.filter((responseElement) =>
        results.some(
          (resultElement) => responseElement.item.Link === resultElement.ref
        )
      );
      const resultsWithScores = resultsInItemProps.map((responseElement) => {
        const matchingResult = results.find(
          (resultElement) => responseElement.item.Link === resultElement.ref
        );
        return {
          item: responseElement.item,
          parents: responseElement.parents,
          score: matchingResult ? matchingResult.score : 0, // Use matching score, or default to 0 if not found
        };
      });
      const sortedResults = resultsWithScores.sort((a, b) => b.score - a.score);

      const filteredResults = this.getFilteredResults(
        sortedResults,
        this.state.selectedNavItem
      );
      this.setState({
        items: {
          allItems: sortedResults,
          filteredItems: filteredResults.slice(0, this.props.displayCount || 5),
        },
        selectedIndex: 0,
      });
    } else {
      // initially, show an empty picker.
      this.setState({ items: this.getInitialItems(), selectedIndex: 0 });
    }
  };

  // Highlight the given item
  selectIndex(ct: number) {
    if (this.props.onChangeHighlightedItem) {
      this.props.onChangeHighlightedItem(this.state.items.filteredItems[ct]); // fire a callback
    }
    this.setState({ selectedIndex: ct }); // update the state for real
  }

  onClickOnBg = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget && this.props.onClose) {
      this.setState({
        items: this.getInitialItems(),
        query: '',
        selectedNavItem: 'All',
      });
      this.props.onClose();
    }
  };

  // On clicking any NavItem in search e.g All or Models etc
  // This function gets all filtered results for that section and sets the state accordingly
  // Furthermore it also sets the corresponding selected index
  onNavItemClick = (selectedNavItem: AllSearchSections) => {
    const items = this.getFilteredResults(
      this.state.items.allItems,
      selectedNavItem
    );

    this.setState((prevStates) => ({
      ...prevStates,
      selectedNavItem,
      items: { ...prevStates.items, filteredItems: items },
      selectedIndex: 0,
    }));

    this.inputRef.current?.focus();
    const containerRef = this.searchListRef.current;
    if (containerRef) {
      containerRef.scrollTop = 0;
    }
  };

  // This function returns the filtered results depending on selectedNavItem in search
  getFilteredResults = (
    allItems: UpdatedNavItemWrapper[],
    selectedNavItem: AllSearchSections
  ) => {
    if (selectedNavItem === 'All') return allItems;
    else return this.getFilteredList(allItems, selectedNavItem);
  };

  createLunrIndex(flattenedLinks: NavItemWrapper[]) {
    const index = lunr(function () {
      // Add the fields to the index
      this.field('Text');

      // Define the reference field (i.e. the unique ID of each document)
      this.ref('Link');
      this.pipeline.remove(lunr.stopWordFilter);
      // Add the documents to the index
      flattenedLinks.forEach((doc) => {
        // Add the document to the index
        this.add(doc.item);
      });
    });
    this.setState({ index });
  }

  //This function returns the correct filtered results depending on selected Nav item in search
  getFilteredList(
    allItems: UpdatedNavItemWrapper[],
    selectedNavItem: AllSearchSections
  ) {
    return allItems.filter((item) => item.item.InSection === selectedNavItem);
  }

  onSearchResultClicked = (item: NavItem, ct: number) => {
    const { Text, Link } = item;

    this.props.onChange &&
      this.props.onChange(this.state.items.filteredItems[ct]);

    logEventOnClickingSearchResult(
      this.props.portalSettings,
      this.state.query,
      Text || '',
      Link
    );

    this.setState({
      items: this.getInitialItems(),
      query: '',
      selectedNavItem: 'All',
    });
  };

  componentDidMount() {
    this.createLunrIndex(this.props.items);
    this.getFilteredResults(
      this.state.items.allItems,
      this.state.selectedNavItem
    );
  }

  componentDidUpdate(prevProps: FullTextpickerCompProps) {
    if (this.props.items !== prevProps.items) {
      this.createLunrIndex(this.props.items);
    }
  }

  render() {
    const isQueryEmpty = !this.state.query.length;
    const arrowColor =
      this.props?.portalSettings?.themeOverrides?.palette?.colors?.C600;
    if (this.props.isOpen) {
      return ReactDOM.createPortal(
        <PopupBackground onClick={this.onClickOnBg}>
          <SearchContainer>
            <SearchInstructions>
              Press <strong>tab</strong> or <UpDownArrows stroke={arrowColor} />{' '}
              to navigate results, <RightLeftArrows stroke={arrowColor} /> to
              switch tabs{', '}
              <strong>enter</strong> to select, <strong> esc</strong> to dismiss
            </SearchInstructions>

            <SearchInput
              className="fuzzy-input"
              type="text"
              autoFocus={true}
              onKeyDown={this.onKeyDown}
              onChange={this.onInputChanged}
              ref={this.inputRef}
            />
            <SearchNavContainer>
              <SearchNavItem
                name={'All'}
                count={this.state.items.allItems.length}
                selectedNavItem={this.state.selectedNavItem}
                onNavItemClick={this.onNavItemClick}
              />
              <SearchNavItem
                name={'API Endpoints'}
                count={
                  this.getFilteredList(
                    this.state.items.allItems,
                    'API Endpoints'
                  ).length
                }
                selectedNavItem={this.state.selectedNavItem}
                onNavItemClick={this.onNavItemClick}
              />
              <SearchNavItem
                name={'Models'}
                count={
                  this.getFilteredList(this.state.items.allItems, 'Models')
                    .length
                }
                selectedNavItem={this.state.selectedNavItem}
                onNavItemClick={this.onNavItemClick}
              />
              <SearchNavItem
                name={'Documentation'}
                count={
                  this.getFilteredList(
                    this.state.items.allItems,
                    'Documentation'
                  ).length
                }
                selectedNavItem={this.state.selectedNavItem}
                onNavItemClick={this.onNavItemClick}
              />
            </SearchNavContainer>
            <SearchListContainer>
              {!this.state.items.filteredItems.length && (
                <EmptyListContainer>
                  <SearchDocumentIcon stroke={staticColors.Goose.C200} />
                  <SearchMessage isError={!isQueryEmpty}>
                    {isQueryEmpty
                      ? 'Start Typing to search'
                      : 'No results found. Try a different search'}
                  </SearchMessage>
                </EmptyListContainer>
              )}
              <SearchList className="fuzzy-items" ref={this.searchListRef}>
                {this.state.items.filteredItems.map(
                  (item: UpdatedNavItemWrapper, ct) => {
                    // render each item
                    return (
                      <ListItemContainer key={ct}>
                        <SearchListItem
                          className={
                            ct === this.state.selectedIndex ? 'selected' : ''
                          }
                          key={
                            this.props.itemKey
                              ? this.props.itemKey(item)
                              : this.props.itemValue
                              ? this.props.itemValue(item)
                              : (item as unknown as React.ReactText).toString()
                          }
                          selected={ct === this.state.selectedIndex}
                          onMouseOver={this.selectIndex.bind(this, ct)}
                          onClick={() => {
                            this.onSearchResultClicked(item.item, ct);
                          }}
                        >
                          {this.props?.renderItem?.(
                            item as UpdatedNavItemWrapper,
                            ct === this.state.selectedIndex,
                            this.state.query
                          )}
                        </SearchListItem>
                        <Line />
                      </ListItemContainer>
                    );
                  }
                )}
              </SearchList>
            </SearchListContainer>
          </SearchContainer>
        </PopupBackground>,
        document.body
      );
    } else {
      return null;
    }
  }
}
