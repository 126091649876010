import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ClosedLockIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '30'}
    height={props.height || '30'}
    viewBox={props.viewBox || '0 0 28 30'}
    fill={props.fill || 'none'}
  >
    <circle
      cx="14"
      cy="14"
      r="13.35"
      fill="none"
      stroke={props.stroke || '#28C397'}
      strokeWidth="1.5"
    />
    <path
      d="M18.6667 13.3335H9.33333C8.59695 13.3335 8 13.9304 8 14.6668V19.3335C8 20.0699 8.59695 20.6668 9.33333 20.6668H18.6667C19.403 20.6668 20 20.0699 20 19.3335V14.6668C20 13.9304 19.403 13.3335 18.6667 13.3335Z"
      stroke={props.stroke || '#28C397'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6667 13.3335V10.6668C10.6667 9.78277 11.0179 8.93493 11.6431 8.30981C12.2682 7.68469 13.116 7.3335 14.0001 7.3335C14.8841 7.3335 15.732 7.68469 16.3571 8.30981C16.9822 8.93493 17.3334 9.78277 17.3334 10.6668V13.3335"
      stroke={props.stroke || '#28C397'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);
