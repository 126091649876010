import { createRef, PureComponent, RefObject } from 'react';
import styled from 'styled-components';

import RcMenu, { ItemGroup, MenuKeyChangeEvent } from 'rc-menu';
import { RouteComponentProps, withRouter } from 'react-router';
import { Div, P } from '../CleanSlate';
import { RcMenuWrapper, RcMenuExpandAnimation } from '../RcMenuWrapper';
import { SidebarProps } from '../Sidebar/Sidebar';
import { menuItems } from './MenuItem';
import { HandleOutsideClick } from 'src/HandleOutsideClick';
import * as ReactDOM from 'react-dom';
import { MenuScroll } from './MenuScroll';
import { menuClickedEvent } from 'src/Analytics';
import { PortalContextConsumer } from 'src/PortalContext';
import { ChevronIcon } from 'src/Icons/Ui/ChevronIcon';
import { PortalSettings } from '../PortalSettings';
import {
  NavItemTextAndLinkWrapper,
  getFlattenedNavItems,
} from 'src/Utilities/utility';

interface RMenuState {
  activeKeyTittle: string | undefined;
  toggleMenuSelect: boolean;
}

type RMenuProps = MenuBoxProps & {
  selected: string;
  portalSettings: PortalSettings;
};

export const MenuSelect = styled(Div)`
  background: ${(props) => props.theme.colors.C001};
  width: 100%;
  max-width: 197px;
  height: 32px;
  padding: 0 12px !important;
  font-size: 14px;
  line-height: 30px;
  border: 1px solid ${(props) => props.theme.colors.C300};
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: space-between;

  p {
    width: calc(100% - 12px);
    height: 30px;
    color: ${(props) => props.theme.colors.C801};
    margin: 0;
    float: left;
    font-size: 13.33px;
    font-weight: 400;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    cursor: pointer;
  }

  @media screen and (min-width: 991px) {
    display: none;
  }

  @media screen and (max-width: 575px) {
    max-width: 100%;
    background: transparent;
    cursor: pointer;
    border-color: transparent;
    border-radius: 0px;

    &,
    p {
      height: 50px;
      line-height: 50px;
    }
  }
`;
MenuSelect.displayName = 'MenuSelect';

/**
 * Temporary fix
 * Todo: Need to remove this wrapper additional style
 */
export const RMenuWrapperStyle = styled(Div)`
  height: calc(100% - 100px);
  margin-top: 30px;

  &,
  #outsideClickWrapper {
    width: 100%;
  }

  #outsideClickWrapper {
    height: 100%;
  }

  @media screen and (max-width: 990px) {
    &,
    #outsideClickWrapper {
      max-width: 197px;
    }
    & {
      height: 32px;
      margin-top: 0;
    }
  }
  @media screen and (max-width: 575px) {
    &,
    #outsideClickWrapper {
      max-width: 100%;
      height: 50px;
      flex: 1;
    }
  }
`;
RMenuWrapperStyle.displayName = 'RMenuWrapperStyle';

/**
 * Customize vertical menu styling
 */
const RMenuStyles = styled(RcMenuWrapper)<{ shouldTitleCollapse?: boolean }>`
  width: 264px;

  @media screen and (max-width: 1200px) {
    width: 234px;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    margin-top: 0;
  }
  @media screen and (max-width: 575px) {
    max-width: 100%;
    flex: 1;
  }

  .rc-menu {
    font-size: ${({ theme: { cssStyles } }) =>
      (cssStyles && cssStyles.body.text2.fontSize) || '13.33px'};
    overflow: hidden;
    margin: 0;
    padding: 0;
    margin-left: 8px;

    /* SUB section padding */
    .rc-menu-sub {
      &.rc-menu-inline {
        padding-left: ${(props) =>
          props.shouldTitleCollapse ? '10px' : '7px'} !important;
      }
    }

    &.rc-menu-root {
      > li {
        .rc-menu-item-group-list {
          > li {
            padding-left: 7px;
          }
        }
      }
      @media screen and (max-width: 990px) {
      }
    }

    .rc-menu-item-group {
      margin-bottom: ${(props) => (props.shouldTitleCollapse ? '0px' : '30px')};
      /* common menue item styles starts */
      .rc-menu-item-group-title,
      .rc-menu-submenu-title,
      .rc-menu-item {
        line-height: 1.5;
        cursor: pointer;
        margin: 3px 0px;
        border-radius: 8px;
        word-break: break-word;
      }
      /* common menue item styles ends */

      /* menue 1st level title starts */
      .rc-menu-item-group-title {
        padding-left: 12px !important;
        color: ${(props) => props.theme.colors.C600};
        font-weight: 500;
        text-transform: uppercase;
      }
      /* menue 1st level title ends */

      /* menue 2nd level ends */
      .rc-menu-item-group-list {
        list-style: none;
      }

      .rc-menu-item-group-list > .rc-menu-item {
        padding: 0px !important;
        border-radius: 0px 8px 8px 0px;
        &:hover {
          background-color: transparent;
        }
        &.rc-menu-item-selected {
          background-color: transparent;
          > a {
            border-radius: 8px;
            background-color: ${(props) =>
              props.theme.staticColors.Unique.C900};
          }
        }

        > a {
          color: ${(props) => props.theme.colors.C901};
          padding: 5px 6px 6px 12px !important;
          text-decoration: none;
          border-radius: 8px;
          display: block;
          &:hover {
            background-color: ${(props) =>
              props.theme.staticColors.Unique.C900} !important;
          }
        }
      }
      /* menue 2nd level ends */

      /* menue 3rd level / submenu starts */
      .rc-menu-item-group-list > .rc-menu-submenu {
        &.rc-menu-submenu-selected {
          background-color: ${(props) => props.theme.colors.C001};
          border-radius: 8px;
        }
        &.rc-menu-submenu-open {
          padding-bottom: 5px;
        }
        .rc-menu-submenu-title {
          padding: 6px 30px 6px 5px !important;
          color: ${(props) => props.theme.colors.C901};
          font-weight: 400;
        }

        &:not(.rc-menu-submenu-open) {
          .rc-menu-submenu-arrow {
            top: -1px;
          }
        }

        .rc-menu-sub {
          &.rc-menu {
            background: transparent;
          }

          .rc-menu-submenu {
            .rc-menu-submenu-title {
              padding: 5px 23px 5px 5px !important;
              border: none;
            }
            .rc-menu-sub {
              margin-left: 0;
            }
            .rc-menu-item > a {
              padding: 6px 8px 6px
                ${(props) => (props.shouldTitleCollapse ? '8px' : '0px')} !important;
            }
            &:not(.rc-menu-submenu-open) {
              .rc-menu-submenu-arrow {
                top: -1px;
              }
            }
          }

          .rc-menu-item {
            padding: 0 0 0
              ${(props) => (props.shouldTitleCollapse ? '0px' : '8px')} !important;
            margin: 2px 5px 2px 0px;
            > a {
              padding: 5px 8px 5px
                ${(props) => (props.shouldTitleCollapse ? '8px' : '0px')} !important;
              color: ${(props) => props.theme.colors.C901};
              text-decoration: none;
              display: flex;
              align-items: flex-start;
              line-height: inherit;
            }
          }
        }
      }
      /* menue 3rd level / submenu ends */

      /* menu submenu active state starts */

      .rc-menu-submenu-selected > .rc-menu-submenu-title {
        color: ${(props) => props.theme.colors.C901}!important;
      }
      .rc-menu-item-selected {
        background-color: ${(props) => props.theme.staticColors.Unique.C900};
        font-weight: 500;
        > a {
          color: ${(props) => props.theme.staticColors.Unique.C1000} !important;
        }
      }

      .rc-menu-item-active,
      .rc-menu-submenu-active > .rc-menu-submenu-title {
        background-color: ${(props) => props.theme.staticColors.Unique.C900};
      }

      /* menu submenu active state starts */
    }
  }
  // show arrow on hover state or when submenu is open state
  /* .rc-menu-submenu-inline {
    .rc-menu-submenu-arrow {
      

      @media (max-width: 990px) {
        visibility: visible;
      }
    }

    &:hover,
    &.rc-menu-submenu-open {
      .rc-menu-submenu-arrow {
        visibility: visible;
      }
    }
  } */
`;
RMenuStyles.displayName = 'RMenuStyles';

/**
 * Menu for navigating between sections
 */
class RMenu extends PureComponent<RMenuProps, RMenuState> {
  menuDropdownRef: RefObject<HTMLSpanElement>;
  flattenedNavItems: NavItemTextAndLinkWrapper[] = [];
  state: RMenuState = {
    activeKeyTittle: undefined,
    toggleMenuSelect: false,
  };
  constructor(props: RMenuProps) {
    super(props);
    this.menuDropdownRef = createRef();
    this.flattenedNavItems = getFlattenedNavItems(this.props.nav);

    const activeKeyTittle = this.getActiveKeyTitle(this.props.selected);

    this.state = {
      activeKeyTittle:
        activeKeyTittle ??
        this.getKeyTitleFromLink(this.props.selected) ??
        'Getting Started',
      toggleMenuSelect: false,
    };
  }

  rcMenuScroll = () => {
    const domNode = ReactDOM.findDOMNode(this);
    if (domNode instanceof HTMLElement) {
      const selectElement = domNode.querySelector('.rc-menu-item-selected');
      if (selectElement) {
        const containerElement = domNode.querySelector(
          '#menu-scroll-container'
        );
        const containerElementHeight =
          containerElement!.getBoundingClientRect().height + 100;
        const seletedElementOffset = selectElement?.getBoundingClientRect().top;

        if (seletedElementOffset > containerElementHeight) {
          domNode.querySelector('#menu-scroll-container')?.scrollTo({
            top:
              domNode.scrollTop +
              selectElement.getBoundingClientRect().top -
              350,
          });
        }
      }
    }
  };
  componentDidUpdate(prevProps: RMenuProps) {
    if (this.props.selected !== prevProps.selected) {
      const isLinkValid = !this.props.selected.includes('x-redirect');
      if (isLinkValid) {
        const activeKeyTittle = this.getActiveKeyTitle(this.props.selected);

        const currentOpenKeys = getDefaultOpenKeys(this.props.selected);

        //Concatination of two arrays but it contains duplicate elements
        const newOpenKeys = this.props.openKeys.concat(currentOpenKeys);
        const uniqueKeys = getUniqueArrayElements(newOpenKeys);

        this.props.onOpenKeysChange(uniqueKeys);

        this.setState({
          activeKeyTittle:
            activeKeyTittle ??
            this.getKeyTitleFromLink(this.props.selected) ??
            'Getting Started',
          toggleMenuSelect: false,
        });

        menuClickedEvent(
          this.props.portalSettings,
          this.props.lang,
          activeKeyTittle,
          this.props.selected
        );
      }
    }
  }
  toggleMenuSelect = () => {
    this.setState((prevState) => ({
      toggleMenuSelect: !prevState.toggleMenuSelect,
    }));
  };

  /**
   * Extracting title form selected menu item key
   */
  getActiveKeyTitle = (selectedKey: string) => {
    const activeKeyTitle = this.flattenedNavItems.find(
      (item) => item.Link === selectedKey
    )?.Text;

    return activeKeyTitle || '';
  };

  getKeyTitleFromLink = (selectedKey: string) => {
    const activeKeyTitle = selectedKey
      .substring(selectedKey.lastIndexOf('/') + 1, selectedKey.length)
      .replace(/-/g, ' ');

    return activeKeyTitle;
  };

  /**
   * Event handler for when a menu item is selected
   */
  onSelect = (ev: MenuKeyChangeEvent) => {
    if (this.props.onSelect) {
      this.props.onSelect(ev.key);
    }
    if (this.props.horizontal) {
      this.props.onOpenKeysChange([]);
    }
    const activeKeyTittle = this.getActiveKeyTitle(ev.key);

    this.setState({
      activeKeyTittle: activeKeyTittle,
      toggleMenuSelect: false,
    });
  };

  onOutsideClick = () => {
    this.setState({
      toggleMenuSelect: false,
    });
  };

  render() {
    const horz = this.props.horizontal;
    const shouldTitleCollapse = Boolean(
      this.props.portalSettings.sideMenuTitleBehavior === 'collapsible'
    );

    return (
      <RMenuWrapperStyle>
        <HandleOutsideClick onOutsideClick={this.onOutsideClick}>
          <MenuSelect onClick={this.toggleMenuSelect}>
            <P>{this.state.activeKeyTittle}</P>
            <ChevronIcon
              viewBox="0 0 24 24"
              width="12px"
              fill="none"
              stroke="#282D44"
            />
          </MenuSelect>

          <MenuScroll
            toggleMenuSelect={this.state.toggleMenuSelect}
            selectedItemKey={this.props.selected}
          >
            <RMenuStyles shouldTitleCollapse={shouldTitleCollapse}>
              <span ref={this.menuDropdownRef} />
              <RcMenu
                mode={horz ? 'horizontal' : 'inline'}
                onSelect={this.onSelect}
                onOpenChange={this.props.onOpenKeysChange}
                openAnimation={horz ? undefined : RcMenuExpandAnimation}
                selectedKeys={[this.props.selected]}
                openKeys={this.props.openKeys}
                // TODO Do not use lambda functions in render()
                getPopupContainer={() =>
                  this.menuDropdownRef.current === null
                    ? document.body
                    : this.menuDropdownRef.current
                }
                subMenuOpenDelay={horz ? 0.2 : undefined}
              >
                {horz
                  ? menuItems(this.props.nav, undefined, true)
                  : this.props.nav.map((i) => {
                      const hasOneItem = i.SubItems.every((s) => s.Skip);
                      const hasOneItemOrShouldCollapse =
                        hasOneItem || shouldTitleCollapse;
                      return i.Skip ? null : (
                        // If there is only one menu item, then wrap it in a group.

                        <ItemGroup
                          key={i.Link}
                          title={hasOneItemOrShouldCollapse ? '' : i.Text || ''}
                        >
                          {menuItems(
                            hasOneItemOrShouldCollapse ? [i] : i.SubItems,
                            i.Text,
                            shouldTitleCollapse
                          )}
                        </ItemGroup>
                      );
                    })}
              </RcMenu>
            </RMenuStyles>
          </MenuScroll>
        </HandleOutsideClick>
      </RMenuWrapperStyle>
    );
  }
}

type MenuBoxProps = Omit<
  SidebarProps,
  'readyArgs' | 'readyArgsInstance' | 'isWorkflowPage' | 'workflowSteps'
> & {
  openKeys: string[];

  onOpenKeysChange: (keys: string[]) => void;
};

interface RoutedMenuProps extends RouteComponentProps<object>, MenuBoxProps {}

/**
 *  Extract subMenu key from selected item key
 *  to set default openKeys of RcMenu.
 */
function getDefaultOpenKeys(key: string): string[] {
  const splitKey = key.split('/');
  const openKeysArray = [key];

  for (let i = splitKey.length - 1; i > 1; --i) {
    const childKeyArray = splitKey.slice(0, i);
    const childkey = childKeyArray.join('/');
    openKeysArray.push(childkey);
  }

  return openKeysArray;
}

function getUniqueArrayElements(openKeys: string[]) {
  const setOfOpenKeys = new Set(openKeys);
  //Converting type Set<string> to type String[] before returning
  return Array.from(setOfOpenKeys);
}

function checkOpenKeys(key: string[], currentLocation: string): string[] {
  const currentOpenKeys = getDefaultOpenKeys(currentLocation);
  if (key.indexOf(currentOpenKeys[0]) === -1) {
    const newOpenKeys = key.concat(currentOpenKeys);
    return getUniqueArrayElements(newOpenKeys);
  }
  return key;
}

/**
 * Bind with router so that menu can show the currently selected item
 */
export const RoutedMenu = withRouter((props: RoutedMenuProps) => {
  const openKeys: string[] =
    props.openKeys.length > 0
      ? checkOpenKeys(props.openKeys, props.location.pathname)
      : getDefaultOpenKeys(props.location.pathname);

  return (
    <PortalContextConsumer>
      {(portalSettings) =>
        portalSettings && (
          <RMenu
            lang={props.lang}
            nav={props.nav}
            whiteLabel={props.whiteLabel}
            onSelect={props.onSelect}
            selected={props.location.pathname}
            horizontal={props.horizontal}
            disabled={props.disabled}
            onOpenKeysChange={props.onOpenKeysChange}
            openKeys={openKeys}
            portalSettings={portalSettings}
          />
        )
      }
    </PortalContextConsumer>
  );
});
