import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ArrowUpIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M25.172 15.172c0 0.531-0.219 1.031-0.578 1.406l-1.172 1.172c-0.375 0.375-0.891 0.594-1.422 
      0.594s-1.047-0.219-1.406-0.594l-4.594-4.578v11c0 1.125-0.938 1.828-2 1.828h-2c-1.062 
      0-2-0.703-2-1.828v-11l-4.594 4.578c-0.359 0.375-0.875 
      0.594-1.406 0.594s-1.047-0.219-1.406-0.594l-1.172-1.172c-0.375-0.375-0.594-0.875-0.594-1.406s0.219-1.047 
      0.594-1.422l10.172-10.172c0.359-0.375 0.875-0.578 1.406-0.578s1.047 
      0.203 1.422 0.578l10.172 10.172c0.359 0.375 0.578 0.891 0.578 1.422z"
    />
  </SVGIcon>
);
