export interface Colors {
  C000: string;
  C100: string;
  C200: string;
  C300: string;
  C400: string;
  C500: string;
  C600: string;
  C700: string;
  C800: string;
  C900: string;
  C1000: string;
  C1100: string;
  C001: string;
  C201: string;
  C801: string;
  C901: string;
}

// TODO Rename this to some less generic, like defaultColors or something
export const colors = {
  primary: '#0c7ff2',
  link: '#00C7D4',
  grayCool: {
    C000: '#fff',
    C100: '#F9FAFE',
    C200: '#F5F7F8', // Base color
    C300: '#E7E9F2', // Border color
    C400: '#CED2E2', // Input placeholder & hover border
    C500: '#B0B5C9',
    C600: '#858BA5', // Menu Tittle
    C700: '#606E8A', // Icons, data Types in explorer
    C800: '#3E445D', // Text
    C900: '#282D44', // Language Selector, Headings, Dark Base
    C1000: '#1D2236',
    C1100: '#858BA5',
    C001: '#F9FAFE',
    C201: '#F5F7F8',
    C801: '#3E445D',
    C901: '#282D44',
  },
  grayWarm: {
    C000: '#fff',
    C1000: '#272727',
    C900: '#434343',
    C800: '#636363',
    C700: '#818181',
    C750: '#818181', // missing in warm theme
    C600: '#9d9d9d',
    C500: '#b8b8b8',
    C400: '#cfcfcf',
    C300: '#e1e1e1',
    C200: '#f1f1f1',
    C100: '#f9f9f9',
    C1100: '#9d9d9d',
    C001: '#F9FAFE',
    C201: '#F5F7F8',
    C801: '#3E445D',
    C901: '#282D44',
  },
};

type FiveColors = Pick<Colors, 'C000' | 'C100' | 'C200' | 'C300' | 'C400'>;

interface SixColors extends FiveColors {
  C500: string;
}
type SevenColors = Pick<
  Colors,
  'C000' | 'C100' | 'C200' | 'C300' | 'C400' | 'C500' | 'C600'
>;
type UniqueColors = Pick<
  Colors,
  'C000' | 'C100' | 'C200' | 'C300' | 'C400' | 'C500' | 'C600' | 'C700'
>;

export interface StaticColors {
  Blue: SevenColors;
  Goose: FiveColors;
  Snow: FiveColors;
  Teal: FiveColors;
  Green: SevenColors;
  Purple: SixColors;
  Pink: FiveColors;
  Red: SevenColors;
  Orange: SixColors;
  Yellow: FiveColors;
  Unique: UniqueColors;
}

export const staticColors = {
  //Primary Band Colors
  Blue: {
    C000: '#0C7FF2',
    C100: '#3D97F2',
    C200: '#6DAFF2',
    C300: '#9DC7F2',
    C400: '#CEE0F2',
    C500: '#0062FF', // ApiMatic default theme blue color
    C600: '#0099FF', // PUT Method box color
  },
  Goose: {
    C000: '#24313E',
    C100: '#455666',
    C200: '#6B7E8F',
    C300: '#9CAAB8',
    C400: '#B6C6D6',
  },
  Snow: {
    C000: '#D9DEE5',
    C100: '#E7E9F2',
    C200: '#EBF0F5',
    C300: '#F5F7F9',
    C400: '#F8FAFC',
  },
  //Secondary Band Colors
  Teal: {
    C000: '#3AB2D0',
    C100: '#6DCEE6',
    C200: '#98DAE9',
    C300: '#C0E1E9',
    C400: '#F2F2F2',
  },
  Green: {
    C000: '#28C397',
    C100: '#50CBA7',
    C200: '#84CCB7',
    C300: '#ACD2C8',
    C400: '#E6E6E6',
    C500: '#45bf7b', // GET Method box color
    C600: '#2F855A', // Get token success color
  },
  Purple: {
    C000: '#5C6AC3',
    C100: '#838BC3',
    C200: '#D0D2E2',
    C300: '#EEEEEE',
    C400: '#FFFFFF',
    C500: '#9675CC', // PATCH Method box color
  },
  //Tertiary Band Colors
  Pink: {
    C000: '#FA6285',
    C100: '#FA94AB',
    C200: '#FAC6D2',
    C300: '#FADFE5',
    C400: '#F2F2F2',
  },
  Red: {
    C000: '#FF4451',
    C100: '#FF7881',
    C200: '#FFABB0',
    C300: '#FFDEE0',
    C400: '#FFFFFF',
    C500: '#F24722', // DELETE Method box color
    C600: '#F9ECEC',
  },
  Orange: {
    C000: '#FF603D',
    C100: '#FF8A70',
    C200: '#FFB4A3',
    C300: '#FFB4A3',
    C400: '#FFF3F0',
    C500: '#FF9D2B', // POST Method box color
  },
  Yellow: {
    C000: '#F4E969',
    C100: '#F4ED9A',
    C200: '#F6F2BA',
    C300: '#F9F6C5',
    C400: '#FFFEEC',
  },
  Unique: {
    C000: '#00000029', //black shadow
    C100: '#000', //black
    C200: '#F3FCF9', // Light green color on Multi Auth success
    C300: '#EEF8ED', // Light green color on get Token success
    C400: '#437C3C', // dark green color on get Token success
    C500: '#C9190B', // Dark Red color on get Token error
    C600: '#C9190B', // Dark Red color on get Token error
    C700: '#FAEAE8', // Light Red color on get Token error
    C800: '#F3F5F8', // Language Selector card background color
    C900: '#DBE5F0', // Active Nav Item Background color
    C1000: '#053463', // Active Nav Item text color
  },
  CodeSampleSkeleton: {
    C000: '#3F4565',
    C100: '#484F74',
    C200: '#4C547A',
    C300: '#505881',
    C400: '#58618D',
  },
};

export const languageSpecificColors = {
  http: '#0062FF',
  dotNet: '#218BCB',
  java: '#E4463F',
  php: '#7C7FB2',
  python: '#4D8DC0',
  ruby: '#D23426',
  go: '#4BA4CC',
  typescript: '#4A7AC2',
};
