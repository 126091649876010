import styled from 'styled-components';
import { Theme } from '@dx-portal/design-system';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 12px;
  min-width: 300px;
`;

const ModalHeader = styled.div`
  width: 519px;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  border-radius: 8px 8px 0 0;
  background: ${({ theme }) => theme.staticColors.Snow.C200};
`;

const ModalTitle = styled.h5`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ModalBody = styled.h6`
  color: black;
  padding: 0 20px;

  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px;
  gap: 6px;
`;

const Button = styled.button`
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: none;
  border-radius: 8px;
  background: var(--colors-base-white, #fff);

  color: ${({ theme }) => theme.staticColors.Goose.C000};

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;
`;

const ButtonPrimary = styled.button`
  display: inline-flex;
  padding: 7px 24px;
  align-items: center;
  gap: 6px;

  border: none;
  border-radius: var(--spacings-2, 8px);
  background: ${({ theme }) => theme.primaryColor};

  color: ${({ theme }) => Theme.getContrastColor(theme)};

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;
`;

export interface ConfirmationModalProps {
  title?: string;
  body: string;
  onClose: () => void;
  onConfirm: () => void;
}

export function ConfirmationModal({
  body,
  onClose,
  onConfirm,
  title,
}: ConfirmationModalProps) {
  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      // Check if the click is on the overlay, not on the modal content
      onClose();
    }
  };
  return (
    <ModalOverlay
      onClick={handleOverlayClick}
      data-testid={'api-copilot-confirmation-modal'}
    >
      <ModalContainer>
        {title && (
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
        )}
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <ButtonPrimary onClick={onConfirm}>Clear</ButtonPrimary>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
}

export default ConfirmationModal;
