type ThumbsUpIconProps = {
  isActive: boolean;
};

export const ThumbsUpIcon = ({ isActive }: ThumbsUpIconProps) => {
  return isActive ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5001 4.89999L11.6667 8.33332H16.5251C16.7838 8.33332 17.039 8.39357 17.2704 8.50928C17.5019 8.62499 17.7032 8.793 17.8584 8.99999C18.0137 9.20698 18.1186 9.44728 18.1649 9.70185C18.2111 9.95642 18.1975 10.2183 18.1251 10.4667L16.1834 17.1333C16.0824 17.4795 15.8719 17.7836 15.5834 18C15.2949 18.2164 14.944 18.3333 14.5834 18.3333H3.33341C2.89139 18.3333 2.46746 18.1577 2.1549 17.8452C1.84234 17.5326 1.66675 17.1087 1.66675 16.6667V9.99999C1.66675 9.55796 1.84234 9.13404 2.1549 8.82148C2.46746 8.50892 2.89139 8.33332 3.33341 8.33332H5.63341C5.94348 8.33316 6.24736 8.2465 6.51088 8.08309C6.77439 7.91968 6.9871 7.686 7.12508 7.40832L10.0001 1.66666C10.3931 1.67152 10.7799 1.76513 11.1316 1.94049C11.4833 2.11584 11.7909 2.36841 12.0313 2.67933C12.2716 2.99024 12.4387 3.35146 12.5199 3.73599C12.601 4.12053 12.5943 4.51844 12.5001 4.89999Z"
        fill="#6B7E8F"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 8.33331V18.3333"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83325 8.33334V18.3333"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5001 4.89999L11.6667 8.33332H16.5251C16.7838 8.33332 17.039 8.39357 17.2704 8.50928C17.5019 8.62499 17.7032 8.793 17.8584 8.99999C18.0137 9.20698 18.1186 9.44728 18.1649 9.70185C18.2111 9.95642 18.1975 10.2183 18.1251 10.4667L16.1834 17.1333C16.0824 17.4795 15.8719 17.7836 15.5834 18C15.2949 18.2164 14.944 18.3333 14.5834 18.3333H3.33341C2.89139 18.3333 2.46746 18.1577 2.1549 17.8452C1.84234 17.5326 1.66675 17.1087 1.66675 16.6667V9.99999C1.66675 9.55796 1.84234 9.13404 2.1549 8.82148C2.46746 8.50892 2.89139 8.33332 3.33341 8.33332H5.63341C5.94348 8.33316 6.24736 8.2465 6.51088 8.08309C6.77439 7.91968 6.9871 7.686 7.12508 7.40832L10.0001 1.66666C10.3931 1.67152 10.7799 1.76513 11.1316 1.94049C11.4833 2.11584 11.7909 2.36841 12.0313 2.67933C12.2716 2.99024 12.4387 3.35146 12.5199 3.73599C12.601 4.12053 12.5943 4.51844 12.5001 4.89999V4.89999Z"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
