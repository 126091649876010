import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  CopyToClipboardIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  CrossIconSmall,
} from '../../icons';
import {
  Div,
  IconButton,
  P,
  textAreaMixin,
  PrimaryButton,
  H5,
} from '../../theme';
import { ChatMessages, UserFeedback } from '../../types';
import { Tooltip } from '../tooltip/Tooltip';

const FeedbackFormContainer = styled(Div)<{
  hide: boolean;
  platform: 'apiCopilot' | 'dxPortal';
}>`
  height: ${({ platform }) => (platform === 'apiCopilot' ? '200px' : '')};
  display: ${({ hide }) => (hide ? 'none' : '')};
  margin: 12px 0;
  border-top: 1px solid ${({ theme }) => theme.staticColors.Snow.C100};
`;

const FeedbackFormActionButtonsContainer = styled(Div)`
  display: flex;
  padding: 8px 0;
`;

const FeedbackFormComponent = styled(Div)<{ fixedWidth: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.staticColors.Snow.C300};
  border: 1px solid ${({ theme }) => theme.staticColors.Snow.C100};
  border-radius: 4px;
  padding: 12px 13px;
  width: ${({ fixedWidth }) => (fixedWidth ? '450px' : '')};
`;

const RateResponse = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  height: 20px;
  align-items: center;
  gap: 6px;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.staticColors.Goose.C200};
`;

const IconButtonWrapper = styled(IconButton)`
  &:disabled {
    cursor: not-allowed;
  }
`;

const CrossIconWrapper = styled(IconButtonWrapper)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const FeedbackFormTitle = styled(P)`
  margin: 0;
  padding-right: 10px;

  > span {
    opacity: 0.7;
  }
`;

const FeedbackFormActionButtonsContainerForPortal = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeedbackFormTitleForPortal = styled(H5)`
  color: ${({ theme }) => theme.staticColors.Goose.C200};
  margin: 8px 0 0 0;
`;

const FeedbackFormTextArea = styled.textarea`
  ${textAreaMixin}
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.staticColors.Snow.C100};
  margin-top: 8px;
  width: 100%;
  min-height: 48px;
  resize: vertical;
  outline: none;
  min-width: unset;

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.staticColors.Blue.C200};
  }
`;

const FeedbackFormSubmitButton = styled(PrimaryButton)``;

export type Feedback = {
  feedback: UserFeedback;
  completeFeedback: string;
};

export interface FeedbackFormProps {
  platform: 'apiCopilot' | 'dxPortal';
  handleFeedbackSubmission: (feedback: Feedback) => void;
  messages?: ChatMessages;
  onResponseCopy?: () => void;
}

export function FeedbackForm({
  platform,
  handleFeedbackSubmission,
  messages,
  onResponseCopy,
}: FeedbackFormProps) {
  const [hideFeedbackForm, setHideFeedbackForm] = useState<boolean>(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<Feedback | undefined>({
    feedback: undefined,
    completeFeedback: '',
  });
  const [copyTooltipText, setCopyTooltipText] = useState<'Copy' | 'Copied'>(
    'Copy'
  );
  const [disableRatingActionButtons, setDisableRatingActionButtons] =
    useState<boolean>(false);
  const feedbackTextAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (feedback?.feedback && !hideFeedbackForm) {
      feedbackTextAreaRef.current?.focus();
      feedbackTextAreaRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [feedback, hideFeedbackForm]);

  const handleFeedbackUpdate = (feedback: UserFeedback) => {
    setFeedback((prev) => ({
      feedback: feedback,
      completeFeedback: prev?.completeFeedback || '',
    }));
  };

  const handleCompleteFeedbackUpdate: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (e) => {
    setFeedback((prev) => ({
      feedback: prev?.feedback,
      completeFeedback: e.target.value,
    }));
  };

  const handleFormSubmission = () => {
    if (feedback) {
      handleFeedbackSubmission(feedback);
      setFeedback({
        feedback: feedback.feedback,
        completeFeedback: '',
      });
      setHideFeedbackForm(true);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
      setDisableRatingActionButtons(true);
    }
  };

  const FormComponent = () =>
    feedback?.feedback &&
    !hideFeedbackForm && (
      <FeedbackFormComponent fixedWidth={platform === 'dxPortal'}>
        <CrossIconWrapper
          onClick={() => {
            setFeedback({
              feedback: undefined,
              completeFeedback: '',
            });
            setHideFeedbackForm(true);
          }}
        >
          <CrossIconSmall />
        </CrossIconWrapper>
        <FeedbackFormTitle>
          Would you like to provide more details? <span>(optional)</span>
        </FeedbackFormTitle>
        <FeedbackFormTextArea
          ref={feedbackTextAreaRef}
          onChange={handleCompleteFeedbackUpdate}
        />
        <FeedbackFormSubmitButton onClick={handleFormSubmission}>
          Submit
        </FeedbackFormSubmitButton>
      </FeedbackFormComponent>
    );

  const ThumbsUpComponent = () =>
    disableRatingActionButtons ? (
      <IconButtonWrapper
        disabled={disableRatingActionButtons}
        onClick={() => {
          handleFeedbackUpdate('positive');
          setHideFeedbackForm(false);
          setShowSuccessMessage(false);
        }}
      >
        <ThumbsUpIcon isActive={feedback?.feedback === 'positive'} />
      </IconButtonWrapper>
    ) : (
      <Tooltip
        text="I found this helpful"
        placement="bottom"
        children={
          <IconButtonWrapper
            onClick={() => {
              handleFeedbackUpdate('positive');
              setHideFeedbackForm(false);
              setShowSuccessMessage(false);
            }}
          >
            <ThumbsUpIcon isActive={feedback?.feedback === 'positive'} />
          </IconButtonWrapper>
        }
      />
    );

  const ThumbsDownComponent = () =>
    disableRatingActionButtons ? (
      <IconButtonWrapper
        disabled={disableRatingActionButtons}
        onClick={() => {
          handleFeedbackUpdate('negative');
          setHideFeedbackForm(false);
          setShowSuccessMessage(false);
        }}
      >
        <ThumbsDownIcon isActive={feedback?.feedback === 'negative'} />
      </IconButtonWrapper>
    ) : (
      <Tooltip
        text="I didn't find this helpful"
        placement="bottom"
        children={
          <IconButtonWrapper
            onClick={() => {
              handleFeedbackUpdate('negative');
              setHideFeedbackForm(false);
              setShowSuccessMessage(false);
            }}
          >
            <ThumbsDownIcon isActive={feedback?.feedback === 'negative'} />
          </IconButtonWrapper>
        }
      />
    );

  return (
    <FeedbackFormContainer platform={platform} hide={false}>
      {platform === 'apiCopilot' && (
        <>
          <FeedbackFormActionButtonsContainer>
            <Tooltip
              text={copyTooltipText}
              placement="bottom"
              children={
                <CopyToClipboard
                  text={messages ? messages[messages.length - 1].content : ''}
                  onCopy={onResponseCopy}
                >
                  <IconButtonWrapper
                    onClick={() => {
                      setCopyTooltipText('Copied');
                    }}
                    onMouseOut={() => {
                      setCopyTooltipText('Copy');
                    }}
                  >
                    <CopyToClipboardIcon />
                  </IconButtonWrapper>
                </CopyToClipboard>
              }
            />
            {ThumbsUpComponent()}
            {ThumbsDownComponent()}
          </FeedbackFormActionButtonsContainer>
          {FormComponent()}
          <RateResponse hide={!showSuccessMessage}>
            Thank you! Your feedback has been received.
          </RateResponse>
        </>
      )}
      {platform === 'dxPortal' && (
        <FeedbackFormActionButtonsContainerForPortal>
          <FeedbackFormTitleForPortal>
            Was this page helpful?
          </FeedbackFormTitleForPortal>
          <FeedbackFormActionButtonsContainer>
            {ThumbsUpComponent()}
            {ThumbsDownComponent()}
          </FeedbackFormActionButtonsContainer>
          {FormComponent()}
          <RateResponse hide={!showSuccessMessage}>
            Thank you! Your feedback has been received.
          </RateResponse>
        </FeedbackFormActionButtonsContainerForPortal>
      )}
    </FeedbackFormContainer>
  );
}

export default FeedbackForm;
