import * as React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import * as DxDom from './DxDom';
import { simplifyModelReference } from './RModelReference';
import { Markdown } from './Markdown';
import { RCompilableCodeBlockLoader } from './RCompilableCodeBlockLoader';
import { renderEndpointReference } from './REndpointReference';
import { ScrollElement } from './Scroll';
import { LinkMapper, LinkMapperContext } from './LinkMapperContext';
import { withoutEmptyColumnsTable } from './DxDomUtils';
import { PortalContextConsumer, withPortalContext } from './PortalContext';
import { isExternalLink } from './HttpUtils';
import { TitlePortal } from './Header/Title';
import { CodeWithCopy } from './CodeWithCopy';
import { PortalSettings } from './PortalSettings';
import { containerReferences } from './RContainerReferences';
import { DescriptionWrapper } from './RParamInfo';
import {
  Alert,
  TableWrapper,
  ParamName,
  FlexParentMixin,
} from './StyledElements';
import * as CS from './CleanSlate';
import { typeCombinatorContainerRef } from './RTypeCombinatorContainerReference';
import { combinePaths } from './Utilities/utility';
import {
  ListObjects,
  TOCProps,
} from './TableOfContent/TableOfContentComponent';
import Breadcrumb from './uiComponents/Breadcrumb';
import { parseTextFromMarkDown } from './TableOfContent/ContentTable';

function getElement(level: number) {
  switch (level) {
    case 1:
      return CS.H1;
    case 2:
      return CS.H2;
    case 3:
      return CS.H3;
    case 4:
      return CS.H4;
    case 5:
      return CS.H5;
    default:
      return CS.H6;
  }
}

/**
 * Implements rendering for all DX section nodes
 */
export class SectionNodeRenderer implements DxDom.NodeHandler<JSX.Element> {
  _headingLevel = 0;
  callout({ node }: { node: DxDom.Callout }) {
    return (
      <Alert
        type={
          node.CalloutType === DxDom.CalloutType.Danger
            ? 'Danger'
            : node.CalloutType === DxDom.CalloutType.Info
            ? 'Info'
            : 'Warning'
        }
      >
        <Markdown
          source={node.Title ? `**${node.Title}** ${node.Body}` : node.Body}
          inline={true}
        />
      </Alert>
    );
  }

  codeblock({ node }: { node: DxDom.CodeBlock }) {
    const lang = node.Language
      ? node.Language in DxDom.LanguageMap
        ? DxDom.LanguageMap[node.Language as keyof typeof DxDom.LanguageMap]
        : node.Language
      : '';
    return (
      <CodeWithCopy
        text={node.Text}
        lang={lang}
        code={node.Text}
        from={node.From ?? 'Endpoint Resource'}
      />
    );
  }

  endpointreference({ node }: { node: DxDom.EndpointReference }): JSX.Element {
    return (
      <PortalContextConsumer>
        {(portalSettings) =>
          portalSettings && renderEndpointReference(node, this, portalSettings)
        }
      </PortalContextConsumer>
    );
  }

  compilablecodeblock({ node }: { node: DxDom.CompilableCodeBlock }) {
    return <RCompilableCodeBlockLoader node={node} />;
  }

  enumreference({ node }: { node: DxDom.EnumReference }): JSX.Element {
    return simplifyModelReference(node, this);
  }
  structurereference({
    node,
  }: {
    node: DxDom.StructureReference;
  }): JSX.Element {
    return simplifyModelReference(node, this);
  }

  typecombinatorcontainerreference({
    node,
  }: {
    node: DxDom.TypeCombinatorContainer;
  }): JSX.Element {
    return typeCombinatorContainerRef(node, this);
  }

  heading({ node }: { node: DxDom.Heading }) {
    const { SuggestedLink = '', Text = '', Level } = node;

    /**
     * Regex for md heading e.g 1-6 hash in start with whitespace
     * Purpose is to prevent rendering of nested heading
     * ^ represents start of string
     * {1,6} represents number of # occurrence between 1 - 6.
     * /s represents whitespace
     */
    const headingPattern = /^#{1,6}\s/;

    const level = Math.max(1, Level + this._headingLevel);

    // Get Heading ELement e.g h1, h2, h3 ...
    const Heading = getElement(level);

    const ele = (linkMapper?: LinkMapper) => (
      <Heading onDoubleClick={() => logHeadingLink(node)}>
        <Markdown
          // Used for making markdown container inline-block
          className="heading-markdown"
          source={Text.replace(headingPattern, '')}
          inline={true}
        />
        {linkMapper && (
          <Link
            // Helps in hover state
            className="heading-link"
            to={linkMapper(node.SuggestedLink) || ''}
          >
            #
          </Link>
        )}
      </Heading>
    );

    if (SuggestedLink) {
      return (
        <LinkMapperContext.Consumer>
          {(linkMapper) => (
            <ScrollElement name={linkMapper(SuggestedLink!)}>
              {ele(linkMapper)}
            </ScrollElement>
          )}
        </LinkMapperContext.Consumer>
      );
    }

    return ele();
  }

  image({ node }: { node: DxDom.Image }) {
    return (
      <CS.P>
        {isExternalLink(node.Url) ? (
          <CS.Img src={node.Url} alt={node.AlternateText} />
        ) : (
          <PortalContextConsumer>
            {(ctxt) =>
              ctxt && (
                <CS.Img
                  src={combinePaths(ctxt.baseUrl, node.Url)}
                  alt={node.AlternateText}
                />
              )
            }
          </PortalContextConsumer>
        )}
      </CS.P>
    );
  }

  link({ node }: { node: DxDom.Link }) {
    return <CS.A href={node.Url}>{node.Url}</CS.A>;
  }

  paragraph({ node }: { node: DxDom.Paragraph }) {
    return <Markdown source={node.Text} />;
  }

  steppedguide({ node }: { node: DxDom.SteppedGuide }) {
    return (
      <React.Fragment>
        {node.Steps.map((step, i) => {
          const { Title, Nodes, SuggestedLinkLevel } = step;
          const Heading = getElement(SuggestedLinkLevel || 3);

          return (
            <React.Fragment key={i}>
              {Title && (
                <Heading>
                  <Markdown source={Title} inline={true} />
                </Heading>
              )}
              {this.renderNodeList(Nodes)}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  table({ node }: { node: DxDom.Table }) {
    const table = withoutEmptyColumnsTable(node);

    return (
      <TableWrapper overflowX={true} overflowY={true}>
        <CS.Table>
          <CS.Thead>
            <CS.Tr>
              {table.Header.Data.map((d, i) => (
                <CS.Th key={i}>{d}</CS.Th>
              ))}
            </CS.Tr>
          </CS.Thead>
          <CS.Tbody>
            {table.Rows.map((r, i) => (
              <CS.Tr key={i}>
                {r.Data.map((c, j) => (
                  <CS.Td key={j}>
                    <Markdown
                      source={c ? c.replace('<br>', '  \n') : c}
                      inline={true}
                    />
                  </CS.Td>
                ))}
              </CS.Tr>
            ))}
          </CS.Tbody>
        </CS.Table>
      </TableWrapper>
    );
  }

  // Need different cell rendering in case of enumeration table
  // It will not effect actual table component
  enumtable({ node }: { node: DxDom.Table }) {
    const table = withoutEmptyColumnsTable(node);

    return (
      <TableWrapper className={table.Class} overflowX={true} overflowY={true}>
        <CS.Table>
          <CS.Thead>
            <CS.Tr>
              {table.Header.Data.map((d, i) => (
                <CS.Th key={i}>{d}</CS.Th>
              ))}
            </CS.Tr>
          </CS.Thead>
          <CS.Tbody>
            {table.Rows.map((r, i) => (
              <CS.Tr key={i}>
                {r.Data.map((c, j) => (
                  <CS.Td key={j}>
                    {j === 0 ? (
                      <ParamName>{c}</ParamName>
                    ) : (
                      <Markdown
                        source={c ? c.replace('<br>', '  \n') : c}
                        inline={true}
                      />
                    )}
                  </CS.Td>
                ))}
              </CS.Tr>
            ))}
          </CS.Tbody>
        </CS.Table>
      </TableWrapper>
    );
  }

  containerreference({
    node,
  }: {
    node: DxDom.ContainerReference;
  }): JSX.Element {
    return containerReferences(node, this);
  }

  section({
    node,
    hasWorkFlow,
  }: {
    node: DxDom.Section;
    hasWorkFlow?: boolean;
  }) {
    // It prevents rendering of node which includes only heading
    if (node.Nodes.length === 0) {
      return <React.Fragment />;
    }

    const heading = this.heading({
      node: {
        Type: 'heading',
        Text: node.Title,
        Level: 1,
        SuggestedLink: node.SuggestedLink,
      },
    });

    if (node.Title) {
      this._headingLevel++;
    }
    //show bread crumbs here
    const out =
      this._headingLevel === 1 ? (
        <>
          <LinkMapperContext.Consumer>
            {(linkMapper) => (
              <Breadcrumb
                path={linkMapper(node.SuggestedLink!)}
                hasWorkFlow={hasWorkFlow}
              />
            )}
          </LinkMapperContext.Consumer>

          {heading}
          {this.renderNodeList(node.Nodes)}
        </>
      ) : (
        <section className="content-section">
          {heading}
          {this.renderNodeList(node.Nodes)}
        </section>
      );

    if (node.Title) {
      this._headingLevel--;
    }

    return out;
  }

  renderNodeList(nodes: ReadonlyArray<DxDom.SectionNode>): JSX.Element {
    let refParams: string | undefined;

    return (
      <React.Fragment>
        {nodes.map((n, i) => {
          if (n.Type === 'structurereference') {
            if (i === 0) {
              refParams = n.ChildClassesLink;
            }
            n = {
              ...n,
              Index: i,
              ChildClassesLink: i === nodes.length - 1 ? refParams : undefined,
            };
          }

          return (
            <React.Fragment key={'dx-' + i}>
              {DxDom.callNodeHandler(n, this)}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

const DocsContent = styled(CS.Div)`
  ${FlexParentMixin};
  flex: 1;
`;

const Content = styled(CS.Div)`
  padding: 0 20px 40px 20px;
  width: 100%;
  & > div:nth-child(2) {
    h1,
    h2 {
      margin-top: 0;
      display: block;
    }
  }

  @media only screen and (min-width: 1500px) {
    padding: 0 40px 40px;
  }
  @media only screen and (max-width: 1500px) {
    padding: 0 30px 40px;
  }
  @media only screen and (max-width: 1350px) {
    padding: 0 20px 40px;
  }
  @media only screen and (max-width: 1100px) {
    padding: 0 20px 40px;
  }
  @media only screen and (max-width: 990px) {
    padding: 0 40px 40px;
    width: 100%;
    margin-top: 0;
    max-width: none;

    h2:first-child {
      display: block;
    }
  }
  /* a bad hack to correcting the padding on first element in content */
  & > div:first-child > p:first-child,
  & > div:first-child > table:first-child,
  & > div:first-child > ul:first-child,
  & > div:first-child > ol:first-child,
  & > div:first-child > blockquote:first-child,
  & > div:first-child > pre:first-child,
  & > div:first-child > img:first-child {
    margin-top: 0;
  }
  & > :nth-child(2) {
    &,
    *:not(${DescriptionWrapper}) {
      margin-top: 0;
    }
  }
  & a {
    ${CS.anchorMixin}
  }
`;
Content.displayName = 'Content';

interface RSectionDocumentProps {
  section: DxDom.Section;
  portalSettings: PortalSettings;
  hasWorkFlow?: boolean;
  updateTOCProps?: (props: Partial<TOCProps>) => void;
}
interface StateTypes {
  activeList: ListObjects[];
}
class SectionDocumentWithPortalContext extends React.PureComponent<
  RSectionDocumentProps,
  StateTypes
> {
  state: StateTypes = {
    activeList: [],
  };
  scrollCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      const displayObject = parseTextFromMarkDown(
        entry?.target.children[0].outerHTML
      );

      // Sections contains a child element with name attribute which contains the heading's mapped URL.
      // TODO: Code like this where DOM is directly accessed need to be removed from the React codebase.
      const hrefText =
        entry?.target.firstChild?.nodeType === Node.ELEMENT_NODE
          ? (entry?.target.firstChild as HTMLElement).getAttribute('name')
          : '';

      // parseTextFromMarkDown only works in hash routing. Its implementation cannot be fixed so must replace the href value as a workaround for now.
      // TODO: parseTextFromMarkdown usage should be replaced in all places with a better/correct implementation.
      displayObject.href = hrefText || '';

      const isIntersecting = entry.isIntersecting;
      const isObjectInActiveListState =
        this.state.activeList.filter((element) => element.href === hrefText)
          .length > 0;
      //condition is true if any object of activeList state has that particular href provided by the observer.

      if (isIntersecting) {
        this.props.updateTOCProps?.({ activeList: this.state.activeList });
        this.setState((prevState) => ({
          ...prevState,
          activeList: [displayObject, ...prevState.activeList],
        }));
      }

      /* 
      If section leaves the intersection obeserver and is included in activeList state
      then remove it from the activeList state
      */

      if (!isIntersecting && isObjectInActiveListState) {
        this.props.updateTOCProps?.({
          activeList: this.state.activeList.filter((element) => {
            return element.href !== hrefText;
          }),
        });

        this.setState((prevState) => ({
          ...prevState,
          activeList: prevState.activeList.filter((element) => {
            return element.href !== hrefText;
          }),
        }));
      }
    });
  };

  componentDidMount = () => {
    const intersectionOptions = {
      root: document.getElementById('app-layout-docs-container'),
      rootMargin: '-0% 0px -80% 0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver(
      this.scrollCallback,
      intersectionOptions
    );

    const target = Array.from(
      document.getElementsByClassName('content-section')
    );

    for (const item of target) {
      observer.observe(item);
    }
  };

  render() {
    const {
      portalSettings: { titleTemplate },
      section: { Title = '', SuggestedLink },
      hasWorkFlow,
      updateTOCProps,
    } = this.props;
    const renderer = new SectionNodeRenderer();
    const title = renderer.heading({
      node: {
        Type: 'heading',
        Text: Title,
        Level: 1,
        SuggestedLink,
      },
    });
    updateTOCProps?.({
      section: this.props.section,
      hasWorkFlow: hasWorkFlow,
      activeList: this.state.activeList,
    });

    return (
      <DocsContent>
        <Helmet>
          {titleTemplate && (
            <title>{titleTemplate.replace('{title}', Title)}</title>
          )}
        </Helmet>
        {!hasWorkFlow && <TitlePortal>{title}</TitlePortal>}
        <Content>
          {renderer.section({ node: this.props.section, hasWorkFlow })}
        </Content>
      </DocsContent>
    );
  }
}

function logHeadingLink(node: DxDom.Linkable) {
  /* eslint-disable  no-console */
  console.log('Heading permalink => ', node.SuggestedLink);
}
export const RSectionDocument = withPortalContext(
  SectionDocumentWithPortalContext
);
